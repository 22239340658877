:root {
  --primary_color: #b5192a;
  --primary_light: rgba(181, 25, 42, 0.5);
  --black_color: #161414;
  --grey_color: #292828;
  --transition: 300ms ease;
  --head_font: 3rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
}

p {
  font-size: 16px;
}

img {
  object-fit: cover;
  display: block;
  width: 100%;
}

#super {
  overflow: hidden;
}

.container {
  width: 85%;
  height: 100%;
  margin: auto;
}

.container-2 {
  width: 80%;
  height: 100%;
  margin: auto;
}

.container-3 {
  width: 90%;
  height: 100%;
  margin: auto;
}

.text_center {
  text-align: center;
}

/* Link arrow animation */

.linkAnima {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  transition: all var(--transition);
  padding: 1.5rem;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.linkAnima:hover {
  color: #b5192a;
  color: var(--primary_color);
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
  display: flex;
}

.arrow {
  position: relative;
  margin: 0 10px;
  width: 2px;
  height: 18px;
  background: #b5192a;
  background: var(--primary_color);
  display: inline-block;
  transition: 300ms ease;
  z-index: 0;
}

a:hover .arrow::before {
  content: "";
  position: absolute;
  width: 1.3px;
  height: 10px;
  z-index: 1;
  background: #b5192a;
  background: var(--primary_color);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
          transform: rotate(-140deg) translateY(-15px) scale(0);
  top: -14px;
  left: 8px;
  -webkit-animation: arrowShow1 500ms 300ms ease forwards;
          animation: arrowShow1 500ms 300ms ease forwards;
}

@-webkit-keyframes arrowShow1 {
  from {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
            transform: rotate(-140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(1);
            transform: rotate(-140deg) translateY(-15px) scale(1);
  }
}

@keyframes arrowShow1 {
  from {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
            transform: rotate(-140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(1);
            transform: rotate(-140deg) translateY(-15px) scale(1);
  }
}

a:hover .arrow::after {
  content: "";
  position: absolute;
  width: 1.3px;
  height: 10px;
  z-index: 1;
  background: #b5192a;
  background: var(--primary_color);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
          transform: rotate(140deg) translateY(-15px) scale(0);
  top: -14px;
  left: -5px;
  -webkit-animation: arrowShow2 500ms 200ms ease forwards;
          animation: arrowShow2 500ms 200ms ease forwards;
}

@-webkit-keyframes arrowShow2 {
  from {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
            transform: rotate(140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(1);
            transform: rotate(140deg) translateY(-15px) scale(1);
  }
}

@keyframes arrowShow2 {
  from {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
            transform: rotate(140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(1);
            transform: rotate(140deg) translateY(-15px) scale(1);
  }
}

a:hover .arrow {
  -webkit-transform: rotate(90deg) translateY(-8px);
          transform: rotate(90deg) translateY(-8px);
}

/* Hover overlay */
.hover_overlay {
  position: relative;
  z-index: 1;
  padding: 0.3rem 0;
}

.hover_overlay:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #b5192a;
  background-color: var(--primary_color);
  z-index: -1;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 300ms ease-in-out;
  display: inline-block;
}

.hover_overlay:hover:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.hover_overlay_2 {
  position: relative;
  z-index: 1;
  padding: 0.3rem 0;
}

.hover_overlay_2:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #b5192a;
  background-color: var(--primary_color);
  z-index: -1;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 300ms ease-in-out;
  display: inline-block;
}

.hover_overlay_2:hover:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

/* btn */
.btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid #b5192a;
  border: 1px solid var(--primary_color);
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
}

.btn:hover,
.btn:focus {
  color: #fff;
}

.btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: #b5192a;
  background-color: var(--primary_color);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.btn:hover::before,
.btn:focus::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

h1 {
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1rem;
  }
}

/* text focus animatoin */

.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* text animation focus */

/* slide right animation */

.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* slide right animation end */

/* slide left animation */

.slide-in-left {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* slide left animation end  */




/* showcse text animation  */

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-emphasis-color: transparent;
          text-emphasis-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 2s linear infinite;
          animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* showcse text animation end */
