#home {
  overflow-x: hidden;
}

#home .home_showcase {
  min-height: 40rem;
  position: relative;
}

#video {
  position: absolute;
  width: 100%;
  height: 40rem;
  object-fit: cover;
  /* z-index: -1; */
  border: 0;
  outline: 0;
}

/* #home .home_showcase:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  inset: 0;
  z-index: 1;
} */

#home .showcase_content {
  transform: translateY(17rem);
  /* max-width: 45rem; */
  position: relative;
  z-index: 2;
}

#home .showcase_content .top p {
  color: var(--primary_color);
  font-size: 2.5rem;
  /* color: #fff; */
}

#home .showcase_content .top p:last-child {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  color: var(--primary_color);
}

#home .showcase_content .links {
  display: flex;
  /* flex-direction: row; */
  padding: 2rem 0;
}

#home .showcase_content .links .link {
  font-weight: 700;
  justify-content: flex-start;
  text-transform: uppercase;
  padding: 0 2.5rem 0.6rem 0;
  transition: transform var(--transition);
  color: var(--primary_color);
  /* color: #ffffff; */
}

#home .showcase_content .links .link:first-child {
  cursor: context-menu;
}

#home .showcase_content .links .link .arrow_still {
  position: relative;
  margin: 0 10px;
  width: 2px;
  height: 18px;
  background: var(--primary_color);
  display: inline-block;
  transition: 300ms ease;
  z-index: 0;
}

/* ======== WELCOM SECTION ======== */
.home_welcome {
  min-height: 25rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
  z-index: 1;
  /* margin-top: 5rem; */
}

.home_welcome .left {
  align-self: flex-start;
  padding: 1rem 0;
  width: 90%;
  margin-top: 2rem;
}

.home_welcome .left p {
  width: 300px;
  padding: 1rem 0;
  line-height: 1.5;
}

.home_welcome .left p:first-child {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  padding-bottom: 0;
}

#home .home_welcome .left .title {
  font-size: 2.5rem;
  color: var(--primary_color);
}

/* .home_welcome .left p:nth-child(2) {
  font-size: 1.3rem;
  font-weight: 500;
} */

.home_welcome .right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.3rem;
  padding: 0 1rem;
  position: absolute;
}

.home_welcome .right .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  bottom: 3rem;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.home_welcome .right .card:hover .text {
  transform: translate(0rem);
  background-color: var(--grey_color);
}

.home_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.home_welcome .right .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  transition: var(--transition);
  transform: translateY(7rem);
}

.home_welcome .right .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.home_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.5rem;
  margin-bottom: 0;
}

.home_welcome .right .card:hover .text {
  transform: translate(0rem);
  background-color: var(--grey_color);
}

.home_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.home_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.5rem;
}

.home_welcome .right .card:hover .text p:first-child {
  margin-bottom: 0;
  line-height: 1.3;
}

.home_welcome .right .text a {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.home_welcome .right .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 15rem;
  width: 100%;
  background-color: var(--primary_color);
}

/* ======= APP SECTION ======= */

.home_app {
  position: relative;
  min-height: 35rem;
  background: url("../../assets/images/woman-using-phone-car.jpg") no-repeat
    center center/cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  isolation: isolate;
}

.home_app::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.app_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  gap: 3rem;
}

.app_wrapper .left {
  max-width: 100%;
}

.app_wrapper .right {
  max-width: 100%;
}

.app_wrapper .right h1 {
  color: #fff;
  font-size: 2rem;
}

.app_wrapper .right .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.app_wrapper .right .bottom img {
  max-width: 200px;
  object-fit: cover;
  object-position: center;
}

.app_wrapper .right .bottom a {
  font-size: 2.5rem;
  margin: 1rem;
  color: #ece8e8;
  transition: all var(--transition);
}

.app_wrapper .right .bottom a:hover,
.app_wrapper .right .bottom a:focus {
  color: var(--primary_color);
  opacity: 0.8;
}

/* ======= MESSAGE SECTION ======= */

.home_message {
  min-height: 40rem;
  margin: 5rem auto;
}

.home_message .message_wrapper .top h2 {
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 800;
}

.home_message .message_wrapper .top p {
  padding: 0.2rem 0 3rem 0;
}

.home_message .message_wrapper .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.home_message .message_wrapper .bottom .box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_message .message_wrapper .bottom .up,
.home_message .message_wrapper .bottom .down {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.home_message .message_wrapper .bottom .up .box,
.home_message .message_wrapper .bottom .down .box {
  width: 100%;
}

.home_message .message_wrapper .bottom .up img,
.home_message .message_wrapper .bottom .down img {
  border-radius: 10px;
  padding: 0 2px;
}

.home_message .bottom .up .btn:hover::before,
.home_message .bottom .down .btn:hover::before {
  transform: scaleX(1);
}

.home_message .message_wrapper .bottom .up #utube_video,
.home_message .message_wrapper .bottom .down #utube_video {
  max-width: 100%;
  border-radius: 17px;
}

.home_message .bottom .up .btn,
.home_message .bottom .down .btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--primary_color);
  transition: all var(--transition);
}

.home_message .bottom .up .btn:hover,
.home_message .bottom .down .btn:hover {
  color: #fff;
}

.home_message .bottom .up .btn::before,
.home_message .bottom .down .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  transform-origin: top;
  transition: transform var(--transition);
  transform: scaleX(0);
}

.home_message .bottom .up .btn::before,
.home_message .bottom .down .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  transform-origin: top;
  transition: transform var(--transition);
  transform: scaleX(0);
}

/* ######### max-width: 1200px ####### */

@media screen and (max-width: 1200px) {
  .home_welcome .left {
    width: 100%;
  }

  .home_welcome .right {
    width: 68%;
  }

  .app_wrapper .right h1 {
    font-size: 2rem;
  }

  .app_wrapper .right .bottom a {
    font-size: 2rem;
    margin: 1rem 0.5rem;
  }

  /* ======= MESSAGE SECTION =======*/

  .home_message .message_wrapper .bottom .up,
  .home_message .message_wrapper .bottom .down {
    flex-direction: column;
    gap: 1.5rem;
  }

  .home_message .message_wrapper .bottom .up #utube_video,
  .home_message .message_wrapper .bottom .down #utube_video {
    height: 300px;
  }
}

/* ######### max-width: 1000px ####### */

@media screen and (max-width: 1000px) {
  .home_welcome {
    flex-direction: column;
    max-width: 85%;
    margin: auto;
  }

  .home_welcome .left {
    align-self: center;
    margin: 4rem;
    width: 100%;
  }

  .home_welcome .left p {
    width: 100%;
  }

  .home_welcome .right .text {
    height: 37%;
    transform: translateY(0);
    background-color: var(--grey_color);
  }

  .home_welcome .right {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    position: unset;
  }

  .home_welcome .right .card img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .home_welcome .right .text {
    height: 37%;
    transform: translateY(0);
    background-color: var(--grey_color);
  }

  .home_welcome .right .text p:first-child {
    margin-bottom: 0;
  }

  .home_welcome .right .shape {
    bottom: 20rem;
  }

  /* ======= APP SECTION =======*/

  .app_wrapper .right h1 {
    font-size: 1.7rem;
  }

  .home_message .message_wrapper .bottom .up,
  .home_message .message_wrapper .bottom .down {
    flex-direction: column;
    gap: 1.5rem;
  }
}

/* ######### max-width: 600px ####### */

@media screen and (max-width: 600px) {
  #home .showcase_content .top p {
    font-size: 2rem;
  }

  #home .showcase_content .top p:last-child {
    font-weight: 700;
    font-size: 1.7rem;
  }

  #home .showcase_content .links {
    flex-direction: column;
  }

  .home_welcome {
    max-width: 100%;
  }

  .home_welcome .left {
    margin-top: 0;
    padding: 1rem;
  }

  .home_welcome .left p {
    padding: 1rem;
  }

  #home .home_welcome .left .title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary_color);
  }

  .home_welcome .right .shape {
    bottom: 17rem;
    height: 160px;
    margin-bottom: 2rem;
  }

  /* =======APP SECTION ======= */
  .home_app {
    background-attachment: unset;
  }

  .app_wrapper {
    height: 100%;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 0;
  }
}

/* pastor alex  */
#psalex {
  padding: 5rem 0;
}

@media screen and (max-width: 600px) {
  #psalex {
    padding: 0;
    padding-bottom: 4rem;
  }
}
/* pastor alex end */
