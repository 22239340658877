:root {
  --primary_color: #b5192a;
  --primary_light: rgba(181, 25, 42, 0.5);
  --black_color: #161414;
  --grey_color: #292828;
  --transition: 300ms ease;
  --head_font: 3rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
}

p {
  font-size: 16px;
}

img {
  object-fit: cover;
  display: block;
  width: 100%;
}

#super {
  overflow: hidden;
}

.container {
  width: 85%;
  height: 100%;
  margin: auto;
}

.container-2 {
  width: 80%;
  height: 100%;
  margin: auto;
}

.container-3 {
  width: 90%;
  height: 100%;
  margin: auto;
}

.text_center {
  text-align: center;
}

/* Link arrow animation */

.linkAnima {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  transition: all var(--transition);
  padding: 1.5rem;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.linkAnima:hover {
  color: #b5192a;
  color: var(--primary_color);
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
  display: flex;
}

.arrow {
  position: relative;
  margin: 0 10px;
  width: 2px;
  height: 18px;
  background: #b5192a;
  background: var(--primary_color);
  display: inline-block;
  transition: 300ms ease;
  z-index: 0;
}

a:hover .arrow::before {
  content: "";
  position: absolute;
  width: 1.3px;
  height: 10px;
  z-index: 1;
  background: #b5192a;
  background: var(--primary_color);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
          transform: rotate(-140deg) translateY(-15px) scale(0);
  top: -14px;
  left: 8px;
  -webkit-animation: arrowShow1 500ms 300ms ease forwards;
          animation: arrowShow1 500ms 300ms ease forwards;
}

@-webkit-keyframes arrowShow1 {
  from {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
            transform: rotate(-140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(1);
            transform: rotate(-140deg) translateY(-15px) scale(1);
  }
}

@keyframes arrowShow1 {
  from {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(0);
            transform: rotate(-140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(-140deg) translateY(-15px) scale(1);
            transform: rotate(-140deg) translateY(-15px) scale(1);
  }
}

a:hover .arrow::after {
  content: "";
  position: absolute;
  width: 1.3px;
  height: 10px;
  z-index: 1;
  background: #b5192a;
  background: var(--primary_color);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
          transform: rotate(140deg) translateY(-15px) scale(0);
  top: -14px;
  left: -5px;
  -webkit-animation: arrowShow2 500ms 200ms ease forwards;
          animation: arrowShow2 500ms 200ms ease forwards;
}

@-webkit-keyframes arrowShow2 {
  from {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
            transform: rotate(140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(1);
            transform: rotate(140deg) translateY(-15px) scale(1);
  }
}

@keyframes arrowShow2 {
  from {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(0);
            transform: rotate(140deg) translateY(-15px) scale(0);
  }
  to {
    -webkit-transform: rotate(140deg) translateY(-15px) scale(1);
            transform: rotate(140deg) translateY(-15px) scale(1);
  }
}

a:hover .arrow {
  -webkit-transform: rotate(90deg) translateY(-8px);
          transform: rotate(90deg) translateY(-8px);
}

/* Hover overlay */
.hover_overlay {
  position: relative;
  z-index: 1;
  padding: 0.3rem 0;
}

.hover_overlay:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #b5192a;
  background-color: var(--primary_color);
  z-index: -1;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 300ms ease-in-out;
  display: inline-block;
}

.hover_overlay:hover:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.hover_overlay_2 {
  position: relative;
  z-index: 1;
  padding: 0.3rem 0;
}

.hover_overlay_2:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #b5192a;
  background-color: var(--primary_color);
  z-index: -1;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 300ms ease-in-out;
  display: inline-block;
}

.hover_overlay_2:hover:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

/* btn */
.btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid #b5192a;
  border: 1px solid var(--primary_color);
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
}

.btn:hover,
.btn:focus {
  color: #fff;
}

.btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: #b5192a;
  background-color: var(--primary_color);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.btn:hover::before,
.btn:focus::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

h1 {
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1rem;
  }
}

/* text focus animatoin */

.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* text animation focus */

/* slide right animation */

.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* slide right animation end */

/* slide left animation */

.slide-in-left {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* slide left animation end  */




/* showcse text animation  */

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-emphasis-color: transparent;
          text-emphasis-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 2s linear infinite;
          animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* showcse text animation end */

#home {
  overflow-x: hidden;
}

#home .home_showcase {
  min-height: 40rem;
  position: relative;
}

#video {
  position: absolute;
  width: 100%;
  height: 40rem;
  object-fit: cover;
  /* z-index: -1; */
  border: 0;
  outline: 0;
}

/* #home .home_showcase:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  inset: 0;
  z-index: 1;
} */

#home .showcase_content {
  -webkit-transform: translateY(17rem);
          transform: translateY(17rem);
  /* max-width: 45rem; */
  position: relative;
  z-index: 2;
}

#home .showcase_content .top p {
  color: var(--primary_color);
  font-size: 2.5rem;
  /* color: #fff; */
}

#home .showcase_content .top p:last-child {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  color: var(--primary_color);
}

#home .showcase_content .links {
  display: flex;
  /* flex-direction: row; */
  padding: 2rem 0;
}

#home .showcase_content .links .link {
  font-weight: 700;
  justify-content: flex-start;
  text-transform: uppercase;
  padding: 0 2.5rem 0.6rem 0;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  color: var(--primary_color);
  /* color: #ffffff; */
}

#home .showcase_content .links .link:first-child {
  cursor: context-menu;
}

#home .showcase_content .links .link .arrow_still {
  position: relative;
  margin: 0 10px;
  width: 2px;
  height: 18px;
  background: var(--primary_color);
  display: inline-block;
  transition: 300ms ease;
  z-index: 0;
}

/* ======== WELCOM SECTION ======== */
.home_welcome {
  min-height: 25rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative;
  z-index: 1;
  /* margin-top: 5rem; */
}

.home_welcome .left {
  align-self: flex-start;
  padding: 1rem 0;
  width: 90%;
  margin-top: 2rem;
}

.home_welcome .left p {
  width: 300px;
  padding: 1rem 0;
  line-height: 1.5;
}

.home_welcome .left p:first-child {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  padding-bottom: 0;
}

#home .home_welcome .left .title {
  font-size: 2.5rem;
  color: var(--primary_color);
}

/* .home_welcome .left p:nth-child(2) {
  font-size: 1.3rem;
  font-weight: 500;
} */

.home_welcome .right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 1.3rem;
  gap: 1.3rem;
  padding: 0 1rem;
  position: absolute;
}

.home_welcome .right .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  bottom: 3rem;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.home_welcome .right .card:hover .text {
  -webkit-transform: translate(0rem);
          transform: translate(0rem);
  background-color: var(--grey_color);
}

.home_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.home_welcome .right .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  transition: var(--transition);
  -webkit-transform: translateY(7rem);
          transform: translateY(7rem);
}

.home_welcome .right .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.home_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.5rem;
  margin-bottom: 0;
}

.home_welcome .right .card:hover .text {
  -webkit-transform: translate(0rem);
          transform: translate(0rem);
  background-color: var(--grey_color);
}

.home_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.home_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.5rem;
}

.home_welcome .right .card:hover .text p:first-child {
  margin-bottom: 0;
  line-height: 1.3;
}

.home_welcome .right .text a {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.home_welcome .right .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 15rem;
  width: 100%;
  background-color: var(--primary_color);
}

/* ======= APP SECTION ======= */

.home_app {
  position: relative;
  min-height: 35rem;
  background: url(/static/media/woman-using-phone-car.caffc3a1.jpg) no-repeat
    center center/cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  isolation: isolate;
}

.home_app::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.app_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  grid-gap: 3rem;
  gap: 3rem;
}

.app_wrapper .left {
  max-width: 100%;
}

.app_wrapper .right {
  max-width: 100%;
}

.app_wrapper .right h1 {
  color: #fff;
  font-size: 2rem;
}

.app_wrapper .right .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.app_wrapper .right .bottom img {
  max-width: 200px;
  object-fit: cover;
  object-position: center;
}

.app_wrapper .right .bottom a {
  font-size: 2.5rem;
  margin: 1rem;
  color: #ece8e8;
  transition: all var(--transition);
}

.app_wrapper .right .bottom a:hover,
.app_wrapper .right .bottom a:focus {
  color: var(--primary_color);
  opacity: 0.8;
}

/* ======= MESSAGE SECTION ======= */

.home_message {
  min-height: 40rem;
  margin: 5rem auto;
}

.home_message .message_wrapper .top h2 {
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 800;
}

.home_message .message_wrapper .top p {
  padding: 0.2rem 0 3rem 0;
}

.home_message .message_wrapper .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.home_message .message_wrapper .bottom .box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_message .message_wrapper .bottom .up,
.home_message .message_wrapper .bottom .down {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.home_message .message_wrapper .bottom .up .box,
.home_message .message_wrapper .bottom .down .box {
  width: 100%;
}

.home_message .message_wrapper .bottom .up img,
.home_message .message_wrapper .bottom .down img {
  border-radius: 10px;
  padding: 0 2px;
}

.home_message .bottom .up .btn:hover::before,
.home_message .bottom .down .btn:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.home_message .message_wrapper .bottom .up #utube_video,
.home_message .message_wrapper .bottom .down #utube_video {
  max-width: 100%;
  border-radius: 17px;
}

.home_message .bottom .up .btn,
.home_message .bottom .down .btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--primary_color);
  transition: all var(--transition);
}

.home_message .bottom .up .btn:hover,
.home_message .bottom .down .btn:hover {
  color: #fff;
}

.home_message .bottom .up .btn::before,
.home_message .bottom .down .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.home_message .bottom .up .btn::before,
.home_message .bottom .down .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

/* ######### max-width: 1200px ####### */

@media screen and (max-width: 1200px) {
  .home_welcome .left {
    width: 100%;
  }

  .home_welcome .right {
    width: 68%;
  }

  .app_wrapper .right h1 {
    font-size: 2rem;
  }

  .app_wrapper .right .bottom a {
    font-size: 2rem;
    margin: 1rem 0.5rem;
  }

  /* ======= MESSAGE SECTION =======*/

  .home_message .message_wrapper .bottom .up,
  .home_message .message_wrapper .bottom .down {
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .home_message .message_wrapper .bottom .up #utube_video,
  .home_message .message_wrapper .bottom .down #utube_video {
    height: 300px;
  }
}

/* ######### max-width: 1000px ####### */

@media screen and (max-width: 1000px) {
  .home_welcome {
    flex-direction: column;
    max-width: 85%;
    margin: auto;
  }

  .home_welcome .left {
    align-self: center;
    margin: 4rem;
    width: 100%;
  }

  .home_welcome .left p {
    width: 100%;
  }

  .home_welcome .right .text {
    height: 37%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: var(--grey_color);
  }

  .home_welcome .right {
    flex-direction: column;
    width: 100%;
    grid-gap: 2rem;
    gap: 2rem;
    position: unset;
  }

  .home_welcome .right .card img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .home_welcome .right .text {
    height: 37%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: var(--grey_color);
  }

  .home_welcome .right .text p:first-child {
    margin-bottom: 0;
  }

  .home_welcome .right .shape {
    bottom: 20rem;
  }

  /* ======= APP SECTION =======*/

  .app_wrapper .right h1 {
    font-size: 1.7rem;
  }

  .home_message .message_wrapper .bottom .up,
  .home_message .message_wrapper .bottom .down {
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

/* ######### max-width: 600px ####### */

@media screen and (max-width: 600px) {
  #home .showcase_content .top p {
    font-size: 2rem;
  }

  #home .showcase_content .top p:last-child {
    font-weight: 700;
    font-size: 1.7rem;
  }

  #home .showcase_content .links {
    flex-direction: column;
  }

  .home_welcome {
    max-width: 100%;
  }

  .home_welcome .left {
    margin-top: 0;
    padding: 1rem;
  }

  .home_welcome .left p {
    padding: 1rem;
  }

  #home .home_welcome .left .title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary_color);
  }

  .home_welcome .right .shape {
    bottom: 17rem;
    height: 160px;
    margin-bottom: 2rem;
  }

  /* =======APP SECTION ======= */
  .home_app {
    background-attachment: unset;
  }

  .app_wrapper {
    height: 100%;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 2rem 0;
  }
}

/* pastor alex  */
#psalex {
  padding: 5rem 0;
}

@media screen and (max-width: 600px) {
  #psalex {
    padding: 0;
    padding-bottom: 4rem;
  }
}
/* pastor alex end */

.slider_wrapper {
  background-color: var(--black_color);
  min-height: 30rem;
  line-height: 1.3;
  width: 100%;
}

.slider_wrapper .slider_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  color: #fff;
}

.slider_wrapper .slider_top .buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.slider_wrapper .buttons .arrow_btn {
  width: 40px;
  height: 40px;
  border: 1px solid #464545;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
  transition: color var(--transition);
}

.slider_wrapper .slider_top .arrow_btn:hover {
  color: var(--primary_color);
  border-color: var(--primary_light);
}

.slider_wrapper .card {
  max-width: 300px;
  margin-left: 0 1rem;
  transition: background-color var(--transition);
}

.slider_wrapper .card .card_top {
  position: relative;
  display: flex;
  align-items: center;
}

.slider_wrapper .card .card_top img {
  border-radius: 5px 5px 0 0;
  height: 220px;
  object-fit: cover;
}

.slider_wrapper .card_top .text_on_img {
  position: absolute;
  right: 1.5rem;
  width: 200px;
  line-height: 1.2;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: right;
}

.slider_wrapper .card .title,
.slider_wrapper .card .head,
.slider_wrapper .card .name {
  padding-bottom: 0.3rem;
  transition: var(--transition);
}

.slider_wrapper .card .title {
  text-transform: uppercase;
  color: #cccc;
}

.slider_wrapper .card .head {
  color: var(--primary_color);
  color: #fff;
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: capitalize;
  transition: color var(--transition);
}

.slider_wrapper .card .name {
  text-transform: capitalize;
  color: #f4f4f4;
}

.slider_wrapper .card .card_bottom {
  font-size: 50rem;
  color: #fff;
  padding: 1rem;
  background-color: #302f2f;
  height: 210px;
  width: 100%;
  transition: background var(--transition);
}

.slider_wrapper .card:hover .card_bottom {
  background-color: var(--primary_color);
}

.slider_wrapper .card:hover .card_bottom .head {
  color: #fff;
}

@media screen and (max-width: 770px) {
  .slider_wrapper .card {
    max-width: 100%;
    margin: auto;
  }

  .slider_wrapper .card .card_top img {
    height: 250px;
  }
}

#contact_showcase {
  position: relative;
  height: 30rem;
  background: url(/static/media/contact2.44242592.jpg) no-repeat center
    center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  isolation: isolate;
}

#contact_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

#contact_showcase .showcase_wrapper h2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: var(--head_font);
}

/* ======= CARD_ADDRESS SECTION ======= */
#contact_address {
  position: relative;
  margin-bottom: 17rem;
}

#contact_address .contact_wrapper {
  background-color: #949494;
  width: 630px;
  min-height: 200px;
  margin-left: auto;
  padding-left: 2rem;
  position: absolute;
  right: 0;
  top: -2rem;
}

#contact_address .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ebe3e3;
  width: 600px;
  padding: 3rem 2rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: -3rem;
}

#contact_address .card p a {
  color: var(--primary_color);
  font-weight: 600;
  text-decoration: underline;
  transition: color var(--transition);
}

#contact_address .card p a:hover {
  color: #fff;
}

/* ======= CONTACT_PRAYER SECTION ======= */
#contact_prayer {
  background: url(/static/media/prayer.a95a8518.jpg) no-repeat center
    center/cover;
  background-attachment: fixed;
  position: relative;
  min-height: 300px;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

#contact_prayer::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#contact_prayer .prayer_section {
  z-index: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

#contact_prayer .prayer_section {
  position: relative;
  z-index: 1;
  color: #fff;
}

#contact_prayer .prayer_section h2 {
  font-size: 2rem;
}

#contact_prayer .prayer_section a {
  color: #fff;
}

/* ====== CONTACT_FORM SECTION ======= */
#contact_form {
  padding: 3rem 0;
}

#contact_form .form_wrapper ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

#contact_form .form_wrapper label {
  font-weight: 600;
  font-size: 18px;
  color: #5a5a5a;
}

#contact_form .form_wrapper label span {
  font-size: 1.3rem;
  font-weight: 700;
  color: red;
}

#contact_form .form_wrapper input,
#contact_form .form_wrapper select,
#contact_form .form_wrapper textarea {
  display: block;
  width: 500px;
  border: 1px solid #aaaaaa;
  outline: none;
  font-size: 1.2rem;
  color: #aaaaaa;
}

#contact_form .form_wrapper input:focus,
#contact_form .form_wrapper select:focus {
  box-shadow: inset 1px 1px 5px 0px rgba(209, 209, 209, 0.5),
    inset -1px -1px 5px 0px rgba(197, 197, 197, 0.5);
}

#contact_form .form_wrapper input {
  height: 40px;
}

#contact_form .form_wrapper input[type="email"],
#contact_form .form_wrapper select {
  width: 500px;
}

#contact_form .form_wrapper li .name_input {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

#contact_form .form_wrapper li .name_input label {
  font-size: 14px;
  font-weight: 400;
}

#contact_form .form_wrapper li .phone_input {
  display: flex;
  flex-direction: row;
}

#contact_form .form_wrapper li .phone_input .dash {
  width: 10px;
  height: 1px;
  background-color: #5a5a5a;
  margin: 0.5rem;
}

#contact_form .form_wrapper li .radio_campus span {
  display: flex;
  grid-gap: 0.7rem;
  gap: 0.7rem;
}

#contact_form .form_wrapper li .radio_campus span label {
  font-weight: 400;
}

#contact_form .email_input {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

#contact_form input::-webkit-outer-spin-button,
#contact_form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#contact_form input[type="number"] {
  -webkit-appearance: textfield;
          appearance: textfield;
}
/* #contact_form .form_wrapper input[type="checkbox"] {

} */

#contact_form .form_wrapper input[type="submit"] {
  background-color: #fff;
  width: 100px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: var(--primary_color);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: all var(--transition);
}

#contact_form .form_wrapper input[type="submit"]:hover {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 1100px) {
  #contact_form .form_wrapper li .name_input {
    flex-direction: column;
  }

  #contact_form .form_wrapper input,
  #contact_form .form_wrapper select {
    width: 100%;
  }

  #contact_form .form_wrapper input[type="email"] {
    width: 100%;
  }

  #contact_form .form_wrapper li textarea {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  #contact_showcase .showcase_wrapper h2 {
    font-size: 2rem;
  }

  #contact_address {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #contact_address .contact_wrapper {
    width: 100%;
  }

  #contact_address .card {
    width: 100%;
    padding: 1rem;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    line-height: 1.2;
  }

  #contact_prayer .container {
    max-width: 100%;
  }

  #contact_prayer .prayer_section h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  #contact_form .form_wrapper {
    max-width: 100%;
    padding: 1rem;
  }
}


#give_showcase {
  position: relative;
  background: url(/static/media/give-offering.2c2d8e6d.jpg) no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#give_showcase:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(37, 37, 37, 0.6);
  z-index: 0;
}

.give_showcase_wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 4rem;
  gap: 4rem;
}

.give_showcase_wrapper .left {
  justify-self: flex-start;
}

.give_showcase_wrapper .left,
.give_showcase_wrapper .right {
  width: 100%;
}

.give_showcase_wrapper p {
  color: #fff;
}

.give_showcase_wrapper .left p:first-child,
.give_showcase_wrapper .right p:first-child {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 400;
  padding: 1rem 0;
}

.give_showcase_wrapper .right img {
  width: 200px;
  margin-top: 1rem;
  border-radius: 10px;
  margin: 2rem 0;
}

.give_showcase_wrapper .donate_link {
  border: 2px solid #fff;
  color: #fff;
}

.arrow_down {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.arrow_down a {
  font-size: 5rem;
  -webkit-animation: arrow_bounce 1000ms ease-in-out alternate infinite;
          animation: arrow_bounce 1000ms ease-in-out alternate infinite;
  transition: opacity 300ms ease;
}

.arrow_down a:hover,
.arrow_down a:focus {
  opacity: 0.6;
}

@-webkit-keyframes arrow_bounce {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(-20);
            transform: translateX(-20);
  }
}

@keyframes arrow_bounce {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(-20);
            transform: translateX(-20);
  }
}

/* ======== MEDIA QUERY ======= */
@media screen and (max-width: 800px) {
  .give_showcase_wrapper {
    flex-direction: column;
  }

  .give_showcase_wrapper .left p:nth-child(2) {
    max-width: 500px;
  }
}

@media screen and (max-width: 600px) {
  #give_showcase {
    height: 100vh;
  }

  .give_showcase_wrapper {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .give_showcase_wrapper .left p:first-child,
  .give_showcase_wrapper .right p:first-child {
    font-size: 2rem;
  }
}

.hoh-showcase {
  background: url(/static/media/k5.51e92af0.jpeg) no-repeat center center/cover;
  height: 100vh;
}

.hoh-text-showcase {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.hoh-text {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
        .hoh-showcase {
        height: 70vh;
    }
} */

/* our vision */

/* .vision-wrapper {
    padding: 6rem 0;
    width: 100%;
}

.vision-wrapper .vision {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.line-1 {
    background: var(--primary_color);
    height: 3px;
    width: 200px;
}

.vision-wrapper .vision .tt-1 {
    color: #000;
    font-size: var(--head_font);
    font-weight: 700;
    text-align: center;
    margin-top: 2rem;
}


.vision-wrapper .vision .tt-2 {
    color: #000;
    font-size: 17px;
    line-height: 1.6;
    font-weight: 500;
    text-align: left;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.vision-wrapper .vision .tt-3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: var(--primary_color);
}


@media screen and (max-width: 600px) {
    .vision-wrapper .vision .tt-1 {
        font-size: 20px;
    }
    .vision-wrapper .vision .tt-2 {
        font-size: 18px;
    }

} */

/* our vision */

/* OUR VISION 2  */

.vision-wrapper {
  padding: 6rem 0;
  width: 100%;
}

.line-case {
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-case .line-1 {
  background: var(--primary_color);
  height: 3px;
  width: 70%;
}

.about-info-case {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
  margin: 3rem 0;
}

.tt-1 {
  color: var(--primary_color);
  font-size: 3rem;
  line-height: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 5rem;
}

.ttp {
  max-width: 900px;
  text-align: center;
  margin: 1rem auto 2rem auto;
}

.vision-wrapper .vision .tt-2 {
  color: #000;
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
}

.vision-wrapper .vision .tt-3 {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.about-info-case .tt-3 {
  color: #000;
}

@media screen and (max-width: 600px) {
  .vision-wrapper .vision .tt-1 {
    font-size: 1.5rem;
  }

  .vision-wrapper .vision .tt-2 {
    color: #000;
    font-size: 1.1rem;
  }

  .vision-wrapper .vision .tt-3 {
    font-size: 1.5rem;
  }
}

/* OUR VISION 2 END */

/* OUR VALUES HISTORY  */

.values-showcase {
  background: url(/static/media/nnav.579a86d7.jpg) no-repeat center center/cover;
  background-attachment: fixed;
  padding: 5rem 0;
}

.values-text-case {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.values-tt1 {
  color: #fff;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  /* margin-bottom: 1.5rem; */
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .values-tt1 {
    font-size: 1.5rem;
  }
}

.values-tt2 {
  color: #fff;
  /* font-size: 22px; */
  font-size: 17px;
  line-height: 1.7;
  font-weight: 600;
  margin-bottom: 1rem;
}

.values-tt2:nth-child(4) {
  margin-bottom: 0;
}

/* update  */

.values-text-case .psr2 {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  /* margin-bottom: 4rem; */
  line-height: 1.8;
}

.values-text-case .psr3 .linkAnima {
  justify-content: flex-start;
  padding: 0;
  color: #fff;
  font-size: 1.1rem;
}

.values-text-case .ps-picc img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .values-text-case .psr2 {
    font-size: 1.1rem;
  }
  .values-text-case .values-tt1 {
    font-size: 1.5rem;
    text-align: center;
  }
  .values-text-case .ps-picc img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.alex-docas .ps-left .psl-pic img {
  height: 400px;
  width: 100%;
  /* object-fit: cover; */
}

/* update end */

/* OUR VALUES HISTORY END */

/* BIBLICAL  */

/* .biblical-wrapper {
    padding: 5rem 0;
} */

.biblical-wrapper .biblical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

/* .biblical-wrapper .biblical .bib {
    margin: 1rem 0;
} */

.biblical-wrapper .biblical .bib .bib-1 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 600px) {
  .biblical-wrapper .biblical .bib .bib-1 {
    font-size: 1.3rem;
  }
}

.biblical-wrapper .biblical .bib .bib-2 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  line-height: 1.7;
  margin-left: 1rem;
}

/* .biblical-wrapper .biblical .bib .bib-2 span {
    color: var(--primary_color);
    cursor: pointer;
} */

.biblical-wrapper .biblical .bib .bib-1 span {
  color: var(--primary_color);
  cursor: pointer;
  margin-left: 1rem;
}

/* BIBLICAL  */

/* INTERESTED  */

.interested-wrapper {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.interested {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.int {
  font-size: 40px;
  color: #000;
  font-weight: 700;
  margin: 2rem 0;
}

@media screen and (max-width: 600px) {
  .int {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .interested-wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.hoo a {
  color: var(--primary_color);
}
.hoo a:hover {
  color: #000;
}
.hoo {
  color: var(--primary_color);
}
.hoo:hover {
  color: #000;
}

.hoo-1 .hoo:hover {
  color: #000;
}

/* INTERESTED END */

/* KINGDOM & HIGHWAY */

.kg-hh-wrapper {
  padding: 5rem 0;
  max-width: 90%;
  margin: auto;
}

.kg-hh {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.kg {
  width: 32%;
  /* width: 45%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.kg-1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--primary_color);
}

@media screen and (max-width: 600px) {
  .kg-1 {
    font-size: 19px;
  }
}

.kg-2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.kg-3 .links {
  color: #000;
  justify-content: flex-start;
  padding: 0;
}

.hh-3 .links {
  color: #000;
  justify-content: flex-start;
  padding: 0;
}
.kg-3:hover .links {
  color: var(--primary_color);
}
.hh-3 .links:hover {
  color: var(--primary_color);
}

.hh {
  /* width: 45%; */
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.klm-case {
  width: 32%;
}

.hh-1a {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--primary_color);
}

@media screen and (max-width: 600px) {
  .hh-1a {
    font-size: 19px;
  }
}

.hh-2a {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .kg-hh {
    flex-direction: column;
  }

  .kg {
    width: 100%;
    margin: auto;
    margin-bottom: 4rem;
  }
  .hh {
    width: 100%;
    margin: auto;
  }

  .klm-case {
    width: 100%;
    margin-top: 2rem;
  }

  .hh-3 .links {
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 850px) {
  .kg-hh {
    flex-direction: column;
  }

  .kg {
    width: 100%;
    margin: auto;
    margin-bottom: 4rem;
  }
  .hh {
    width: 100%;
    margin: auto;
  }

  .klm-case {
    width: 100%;
    margin-top: 2rem;
  }
}

.abt-hp {
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
}

.ps-l {
  width: 50%;
}

.ps-r {
  width: 50%;
}

/* KINGDOM & HIGHWAY END */

/* PASTOR ALEX  */

.ps-alex-wrapper {
  width: 100%;
}

.ps-alex-case {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-left {
  width: 50%;
}

.psl-pic img {
  /* object-fit: cover; */
  object-fit: contain;
  width: 100%;
  height: 500px;
  /* clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%); */
}

.ps-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2rem;
  margin-right: 2rem;
}

.psr1 {
  font-size: 1.6rem;
  color: var(--primary_color);
  font-weight: 800;
}

.psr2 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 1rem 0;
  line-height: 1.8;
}

.psr3 .linkAnima {
  justify-content: flex-start;
  padding: 0;
  color: #000;
  font-size: 16px;
}

.psr3 .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 950px) {
  .ps-alex-case {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    max-width: 95%;
    margin: auto;
  }

  .ps-right {
    width: 100%;
    margin-left: 0;
    margin-top: 4rem;
    margin-right: 0;
  }

  .ps-left {
    width: 100%;
    margin-top: 4rem;
  }

  .psl-pic img {
    height: 420px;
    -webkit-clip-path: none;
            clip-path: none;
  }

  .psr1 {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .psl-pic img {
    height: 360px;
    width: 100%;
    object-fit: contain;
  }
  .psr1 {
    font-size: 1.2rem;
    color: #000;
    font-weight: 800;
  }
}

/* PASTOR ALEX  END */

/* MISSION STATEMENT  */

.ms-stat-wrapper {
  width: 100%;
  background: url(/static/media/nnav.579a86d7.jpg) no-repeat center center/cover;
  background-attachment: fixed;
  margin: 5rem 0;
}

.ms-stat-case {
  max-width: 90%;
  margin: auto;
  color: #fff;
  padding: 5rem 0;
}

.ms-stat-show {
  position: relative;
}

.ms-stat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  width: 90%;
  margin: auto;
}

.ms1 {
  font-weight: 800;
  text-align: center;
  font-size: 25px;
  margin-bottom: 2rem;
}

.ms2 {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.7;
  text-align: center;
}
.ms22 {
  font-weight: 600;
  font-size: 17px;
  margin-top: 1rem;
  line-height: 1.7;
}

/* span {
    font-size: 50px;
} */

/* span:first-child {
    text-align: left;
}
span:last-child {
    text-align: right;
} */

.l-shape {
  position: relative;
  height: 100px; /* reduce the height to make it thinner */
}

.box-1 {
  position: absolute;
  width: 10px; /* reduce the width to make it thinner */
  height: 100%; /* occupy full height of parent element */
  background-color: var(--primary_color); /* set the same color */
  top: 3rem;
  left: 0;
}

.box-2 {
  position: absolute;
  width: 200px; /* occupy full width of parent element */
  height: 10px; /* reduce the height to make it thinner */
  background-color: var(--primary_color); /* set the same color */
  bottom: 50px;
  left: 0;
}

.l-shape2 {
  position: relative;
  height: 100px; /* reduce the height to make it thinner */
  /* transform: rotate(90deg); */
}

.box-12 {
  position: absolute;
  width: 10px; /* reduce the width to make it thinner */
  height: 100%; /* occupy full height of parent element */
  background-color: var(--primary_color); /* set the same color */
  top: 0;
  right: 0;
}

.box-22 {
  position: absolute;
  width: 200px; /* occupy full width of parent element */
  height: 10px; /* reduce the height to make it thinner */
  background-color: var(--primary_color); /* set the same color */
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 600px) {
  .ms-stat-case {
    max-width: 90%;
    padding: 2rem 0;
  }

  .box-1 {
    position: absolute;
    width: 10px; /* reduce the width to make it thinner */
    height: 100%; /* occupy full height of parent element */
    background-color: #f00; /* set the same color */
    top: 3rem;
    left: 0;
  }

  .box-2 {
    position: absolute;
    width: 200px; /* occupy full width of parent element */
    height: 10px; /* reduce the height to make it thinner */
    background-color: #f00; /* set the same color */
    bottom: 50px;
    left: 0;
  }

  .box-12 {
    position: absolute;
    width: 10px; /* reduce the width to make it thinner */
    height: 100%; /* occupy full height of parent element */
    background-color: var(--primary_color); /* set the same color */
    top: -20px;
    right: 0;
  }

  .box-22 {
    position: absolute;
    width: 200px; /* occupy full width of parent element */
    height: 10px; /* reduce the height to make it thinner */
    background-color: var(--primary_color); /* set the same color */
    bottom: 20px;
    right: 0;
  }
}

/* MISSION STATEMENT END */

#about_showcase {
  position: relative;
  background: url(/static/media/easterService.df7a949e.jpg) no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#about_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#about_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#about_showcase h1 {
  color: #fff;
  font-size: 3rem;
}

#about_showcase .date {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

.about_welcome {
  min-height: 25rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative;
}

.about_welcome .left {
  align-self: flex-start;
  padding: 1rem 0;
  width: 85%;
}

.about_welcome .left p {
  width: 300px;
  padding: 1rem 0;
}

.about_welcome .left p:first-child {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
}

.about_welcome .left a {
  color: #000;
  margin-left: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.about_welcome .left a:focus {
  color: #fff;
}

.about_welcome .left a:hover {
  color: #fff;
}

.about_welcome .right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 1.3rem;
  gap: 1.3rem;
  padding: 0 1rem;
  position: absolute;
}

.about_welcome .right .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  bottom: 3rem;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.about_welcome .right .card:hover .text {
  -webkit-transform: translate(0rem);
          transform: translate(0rem);
  background-color: var(--grey_color);
  height: 60%;
}

.about_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.about_welcome .right .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  transition: var(--transition);
  -webkit-transform: translateY(7rem);
          transform: translateY(7rem);
}

.about_welcome .right .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.about_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.8rem;
}

.about_welcome .right .card:hover .text p:first-child {
  margin-bottom: 0;
  line-height: 1.3;
}

.about_welcome .right .text a {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.about_welcome .right .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 15rem;
  width: 100%;
  background-color: var(--primary_color);
}

#aurora_events {
  max-width: 70%;
  height: 300px;
  margin: auto;
  display: flex;
  align-items: center;
}

#aurora_events h1 {
  font-size: var(--head_font);
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1200px) {
  .about_welcome .left {
    width: 100%;
  }

  .about_welcome .right {
    width: 68%;
  }
}

@media screen and (max-width: 1000px) {
  .about_welcome {
    flex-direction: column-reverse;
    max-width: 85%;
    margin: auto;
  }

  .about_welcome .left {
    align-self: center;
    margin: 4rem;
    width: 100%;
  }

  .about_welcome .left p {
    width: 100%;
  }

  .about_welcome .right {
    flex-direction: column;
    width: 100%;
    grid-gap: 2rem;
    gap: 2rem;
    position: unset;
  }

  .about_welcome .right .card img {
    min-width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .about_welcome .right .text {
    height: 60%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: var(--grey_color);
  }

  .about_welcome .right .text p:first-child {
    margin-bottom: 0;
  }

  .about_welcome .right .shape {
    bottom: 20rem;
  }

  /* ======= EVENT SECTION ======= */
  #aurora_events {
    max-width: 85%;
    height: 100px;
  }

  #aurora_events h1 {
    font-size: 2em;
  }
}

/* ====== About_Showcase ====== */
@media screen and (max-width: 600px) {
  #about_showcase h1 {
    font-size: 2.5rem;
  }

  #about_showcase .date {
    font-size: 1.5rem;
  }

  .about_welcome {
    max-width: 100%;
  }

  .about_welcome .left {
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 1rem;
  }

  .about_welcome .right .shape {
    bottom: 20rem;
    height: 160px;
    margin-bottom: 2rem;
  }

  /* ======= EVENT SECTION ======= */

  #aurora_events h1 {
    font-size: 1.5em;
  }
}

#denver_showcase {
  position: relative;
  background: url(/static/media/eating_group.3dced56e.jpg) no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#denver_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

#denver_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#denver_showcase h1 {
  color: #fff;
  font-size: 3rem;
  max-width: 700px;
  line-height: 1.3;
  text-align: center;
  padding: 1rem;
}

#denver_showcase .date {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

#denver_reach {
  min-height: 400px;
  margin-top: 5rem;
}

#denver_reach .reach_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  height: 100%;
}

.reach_wrapper .left {
  width: 100%;
}

.reach_wrapper .left img {
  width: 500px;
  height: 350px;
  object-position: top center;
  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
}

.reach_wrapper .right {
  max-width: 100%;
  margin-right: 4rem;
}

.reach_wrapper .right h2 {
  font-size: 30px;
  line-height: 1;
}

.reach_wrapper .right p {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.reach_wrapper .right a {
  color: #000;
  justify-content: flex-start;
  padding: 0.5rem 0;
  font-weight: 700;
  transition: var(--transition);
}

.reach_wrapper .right a:hover,
.reach_wrapper .right a:focus {
  color: var(--primary_color);
}

/* ======= SECTION: DENVER EVENTS ======= */

.denver_events_wrapper {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}

.denver_events_wrapper h2 {
  font-size: 32px;
  padding: 1rem 0;
  text-align: center;
}

.denver_events_wrapper .card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.denver_events_wrapper .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  bottom: 3rem;
  cursor: pointer;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.denver_events_wrapper .card:hover .text {
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
  background-color: var(--grey_color);
}

.denver_events_wrapper .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.denver_events_wrapper .card .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 60%;
  width: 100%;
  transition: var(--transition);
  -webkit-transform: translateY(7rem);
          transform: translateY(7rem);
}

.denver_events_wrapper .card .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.denver_events_wrapper .card .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}

.denver_events_wrapper .card:hover .text p:first-child {
  margin-bottom: 0;
}

.denver_events_wrapper .card .text a {
  justify-content: flex-start;
  padding: 0;
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {
  #denver_showcase h1 {
    font-size: 2rem;
  }

  #denver_reach {
    margin-top: 3rem;
    min-height: auto;
  }

  #denver_reach .reach_wrapper {
    flex-direction: column;
    background-color: transparent;
    width: 85%;
    margin: auto;
  }

  .reach_wrapper .left {
    max-width: 100%;
  }

  .reach_wrapper .right {
    padding: 1rem;
    margin-right: 0;
    background-color: #efefef;
  }

  /* ======= EVENT SECTION =======   */
  .denver_events_wrapper {
    width: 85%;
    flex-direction: column;
  }

  .denver_events_wrapper .card_wrapper {
    flex-direction: column;
  }

  .denver_events_wrapper .card_wrapper .text {
    min-height: 40%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: var(--grey_color);
  }

  .denver_events_wrapper .card .text p:first-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  #denver_reach .reach_wrapper {
    width: 100%;
  }

  /* ======= EVENT SECTION =======   */
  .denver_events_wrapper {
    width: 100%;
    padding: 1rem;
  }

  .denver_events_wrapper h2 {
    font-size: 1.6rem;
  }
}

#adult_retreat_showcase {
  position: relative;
  background: url(/static/media/adult_retreat.36009afc.jpg) no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#adult_retreat_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

#adult_retreat_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#adult_retreat_showcase h1 {
  color: #fff;
  font-size: 3rem;
}

#adult_retreat_showcase .date {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

#adult_retreat_showcase .laf_asl {
  color: #fff;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1;
  margin: 1rem;
}

.about_welcome .left .asl_welcome {
  font-size: 18px;
  font-weight: 700;
}

/* ======= MINISTIES SECTION ======= */

#ministries {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0;
  height: auto;
}

#ministries .ministries_wrapper {
  width: 75%;
}

#ministries .ministries_wrapper h1 {
  margin-bottom: 1.5rem;
}

/* ======= SERVE_DIVIDER SECTION ======= */
#serve_divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  min-height: 300px;
  background-color: #000;
  line-height: 1.3;
}

#serve_divider h2 {
  font-size: 3rem;
  color: #fff;
  text-transform: capitalize;
}

#serve_divider .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10rem;
  gap: 10rem;
}
#serve_divider .bottom a {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--primary_color);
  text-transform: uppercase;
}

#serve_divider .bottom a:hover {
  color: #fff;
}

/* ======= LAFAYETTE_EVENTS SECTION ======= */

#lafayette_events .about_welcome {
  flex-direction: column;
  max-width: 90%;
  margin: auto;
}

#lafayette_events .about_welcome h2 {
  align-self: flex-start;
  padding: 2rem 0;
  width: 70%;
  margin: auto;
  font-size: var(--head_font);
}

#lafayette_events .about_welcome .right {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 0 1rem;
  position: none;
  position: relative;
  margin-bottom: 3rem;
}

#lafayette_events .about_welcome .right .card {
  bottom: 0;
}

#lafayette_events .about_welcome .right .text {
  bottom: 0;
  height: 75%;
}

#lafayette_events .about_welcome .right .card:hover .text {
  height: 85%;
}

#lafayette_events .about_welcome .right .text p:first-child {
  margin-bottom: 7rem;
  text-shadow: 1px 1px #000;
}

#lafayette_events .about_welcome .right .card:hover p:first-child {
  margin-bottom: 0;
}

/* ======= LONGMONT_REACH SECTION ======= */
#lafayette_reach {
  min-height: 400px;
  margin: 5rem 0;
}

#lafayette_reach .reach_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  height: 100%;
}

#lafayette_reach .reach_wrapper .right {
  max-width: 100%;
  padding: 1rem 0;
  margin-right: 4rem;
}

#lafayette_reach .reach_wrapper .right {
  padding-left: 1rem;
}

#lafayette_reach .reach_wrapper .right p:nth-child(2) {
  line-height: 1.3;
  font-weight: 700;
}

#lafayette_reach .reach_wrapper .right a {
  transition: var(--transition);
}

/* ====== About_Showcase ====== */

@media screen and (max-width: 1140px) {
  #lafayette_events .about_welcome h2 {
    width: 80%;
  }

  #lafayette_events .about_welcome .right {
    right: -100px;
  }
}

@media screen and (max-width: 1000px) {
  #lafayette_events .about_welcome h2 {
    width: 100%;
  }

  #lafayette_events .about_welcome .right {
    right: 0;
  }

  #lafayette_events .about_welcome .right .text {
    height: 60%;
  }

  #lafayette_events .about_welcome .right .card:hover .text {
    height: 60%;
  }

  #lafayette_events .about_welcome .right .text p {
    margin-bottom: 3rem;
  }

  #lafayette_events .about_welcome .right .text p:first-child {
    margin-bottom: 0rem;
  }

  /* ======= LAFAYETTE_EVENTS SECTION ======= */
  #lafayette_events .about_welcome .right {
    grid-template-columns: 1fr 1fr;
  }

  /* ======= LAFAYETTE_REACH SECTION ======= */
  #lafayette_reach {
    margin-top: 3rem;
    min-height: auto;
  }

  #lafayette_reach .reach_wrapper {
    flex-direction: column;
    background-color: transparent;
    width: 100%;
    margin: auto;
  }

  #lafayette_reach .reach_wrapper .right {
    padding: 1rem;
    margin-right: 0;
  }
}

@media screen and (max-width: 800px) {
  #ministries .ministries_wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  #about_showcase h1 {
    font-size: 2.5rem;
  }

  #about_showcase .date {
    font-size: 1.5rem;
  }

  #lafayette_events .about_welcome {
    max-width: 100%;
  }

  #lafayette_events .about_welcome .left {
    margin-top: 0;
    padding: 1rem;
  }

  #lafayette_events .about_welcome .right .shape {
    bottom: 20rem;
    height: 160px;
    margin-bottom: 2rem;
  }

  #lafayette_events .about_welcome h2 {
    padding: 1rem 2rem;
    font-size: 1.7rem;
    margin-top: 2rem;
  }

  #ministries .ministries_wrapper {
    width: 100%;
    padding: 0 2rem;
  }

  #serve_divider h2 {
    font-size: 2rem;
  }

  #serve_divider .bottom {
    grid-gap: 3rem;
    gap: 3rem;
  }

  #serve_divider .bottom a {
    font-size: 1.3rem;
  }

  /* ======= LAFAYETTE_EVENTS SECTION ======= */
  #lafayette_events .about_welcome .right {
    grid-template-columns: 1fr;
  }
}

#weekly_programme_showcase {
  position: relative;
  background: url(/static/media/church_auditorium.4e6d61b5.jpg) no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#weekly_programme_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

#weekly_programme_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 1rem;
}

#weekly_programme_showcase h1 {
  color: #fff;
  font-size: 3rem;
  padding: 0 1rem;
}

#weekly_programme_showcase .date {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

#longmont_about {
  margin: 3rem 0;
  padding: 1rem;
}

#longmont_about .about_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#longmont_about h1 {
  font-size: var(--head_font);
  border-top: 2px solid var(--primary_color);
  border-bottom: 2px solid var(--primary_color);
  display: inline-block;
  padding: 0.5rem;
}

#longmont_about p {
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
  padding: 1.5rem;
}

#longmont_about p a {
  color: var(--primary_color);
  transition: var(--transition);
}

#longmont_about p a:hover {
  color: #fff;
}

#longmont_reach {
  min-height: 400px;
  margin-top: 5rem;
}

#longmont_reach .reach_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  height: 100%;
}

#longmont_reach .reach_wrapper .left {
  width: 100%;
}

#longmont_reach .reach_wrapper img {
  width: 500px;
  height: 400px;
  object-position: top center;
}

#longmont_reach .reach_wrapper .right {
  max-width: 100%;
  padding: 1rem 0;
  margin-right: 4rem;
}

#longmont_reach .reach_wrapper .right {
  padding-left: 1rem;
}

/* ======= LONGMONT_PODCAST ======= */
#longmont_podcast {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  min-height: 450px;
}

#longmont_podcast .left {
  width: 60%;
  height: 100%;
  position: relative;
  -webkit-transform: translateY(-7rem);
          transform: translateY(-7rem);
}

#longmont_podcast img {
  height: 350px;
}

#longmont_podcast .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding-top: 3rem;
  -webkit-transform: translateY(-7rem);
          transform: translateY(-7rem);
  padding: 1rem;
}

#longmont_podcast .right h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  width: 50%;
  line-height: 1;
}

#longmont_podcast .right p {
  line-height: 1.5;
  margin: 0.5rem 0;
  max-width: 400px;
}

#longmont_podcast .right .social_icons a {
  color: #000;
  font-size: 3rem;
  line-height: 1;
  margin: 10px;
}

#longmont_podcast .right .linkAnima {
  color: var(--primary_color);
  text-transform: uppercase;
  font-weight: 700;
  justify-content: left;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#longmont_podcast .left .shape_2 {
  -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  height: 270px;
  width: 103%;
  background-color: var(--primary_color);
  position: absolute;
  left: 0;
  bottom: -3rem;
  z-index: -1;
}

/* ======= LONGMONT_MINISTRIES SECTION ======= */
#longmont_ministries {
  margin: 5rem 0;
}

#longmont_ministries .about_welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#longmont_ministries .about_welcome h2 {
  font-size: 2rem;
  text-transform: uppercase;
  align-self: flex-start;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

#longmont_ministries .about_welcome .right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  position: initial;
}

@media screen and (max-width: 1000px) {
  /* ======= LAFAYETTE_REACH SECTION ======= */
  #longmont_reach {
    margin-top: 3rem;
    min-height: auto;
  }

  #longmont_reach .reach_wrapper {
    flex-direction: column;
    background-color: transparent;
    width: 100%;
    margin: auto;
  }

  #longmont_reach .reach_wrapper .right {
    padding: 1rem;
    margin-right: 0;
  }

  #longmont_ministries .about_welcome {
    max-width: 85%;
  }

  /* ======= PODCAST SECTION ==== */
  #longmont_podcast {
    flex-direction: column;
    margin-top: 8rem;
    grid-gap: 3rem;
    gap: 3rem;
  }

  #longmont_podcast .left {
    width: 100%;
  }

  #longmont_podcast .right {
    width: 100%;
  }

  #longmont_podcast .right h1 {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  #longmont_podcast .right p {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  #longmont_about .about_wrapper {
    max-width: 100%;
  }

  #longmont_ministries .about_welcome {
    max-width: 100%;
  }
}


/* ======= LONGMONT_REACH SECTION ======= */
#west_reach {
    min-height: 400px;
    margin-top: 5rem;
  }
  
  #west_reach .reach_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    height: 100%;
  }

  #west_reach .reach_wrapper .right {
    max-width: 100%;
    padding: 1rem 0;
    margin-right: 4rem;
  }
  
  #west_reach .reach_wrapper .right {
    padding-left: 1rem;
  }
  
  #west_reach .reach_wrapper .right p:nth-child(2) {
    line-height: 1.3;
    font-weight: 700;
  }

  /* ======= WEST_MINISTRIES SECTION ======= */
#wester_ministries {
  margin: 5rem 0;
}

#wester_ministries .about_welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wester_ministries .about_welcome h2{
  font-size: 2rem;
  text-transform: uppercase;
  align-self: flex-start;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

#wester_ministries .about_welcome .right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  position: initial;
}

#wester_ministries .about_welcome .right .text {
  height: 60%;
}

/* #wester_ministries .about_welcome .right .text p:first-child {
  margin-bottom: 3rem;
} */

#wester_ministries .about_welcome .right .card:hover .text p:first-child {
  margin-bottom: 0;
  line-height: 1.3;
}


  /* =======MEDIA QUERIES ======= */
  @media screen and (max-width:1000px){
    #west_reach .reach_wrapper {
        flex-direction: column;
      }
  }
  
.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #fff;
  min-width: 700px;
  height: 47px;
  padding: 0px 1px 1px 0px;
  margin-top: 1rem;
}

.form_container p {
  background-color: #fff;
  color: #000;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.4rem;
}

.form_container .input_area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form_container .input_area input {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  padding-left: 1.5rem;
  color: #fff;
  border: 0;
  outline: none;
  background: transparent;
}

.form_container .input_area .placeholder {
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 1.5rem;
  pointer-events: none;
}

.form_container .input_area input:focus ~ .placeholder,
.form_container .input_area input:valid ~ .placeholder {
  display: none;
}

.form_container .arrow_icon {
  /* width: 5%; */
  height: 100%;
  background-color: #fff;
  color: var(--primary_color);
  font-size: 55px;
  cursor: pointer;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
    .form_container {
        min-width: 200px;
    }

  .form_container p {
    display: none;
  }

  .form_container .input_area .placeholder {
    font-size: 1rem;
    font-weight: 500;
  }
}

.group-page {
    background: #fff;
}

/* showcase */
.group-showcase {
    background: url(/static/media/ll1.c4611e78.jpeg) no-repeat center center/cover;
    height: 100vh;
}

.group-case {
    background: rgba(0, 0, 0, .6);
    height: 100%;
}

.group-text {
    color: #fff;
    text-align: center;
    font-weight: 700;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%)
}

/* @media screen and (max-width: 600px) {
    .group-showcase {
    height: 70vh;
}

} */

/* showcase end */


/* shape and map */


.map-shape-wrapper {
    margin-bottom: 40rem;
}

.map-shape {
    position: relative;
}

.mp-sp .mp {
    width: 80%;
    /* height: 900px; */
    position: absolute;
    right: 0;
    top: -100px;
    z-index: 2;
}

.mp-sp .mp img {
    object-fit: cover;
    height: 600px;
    width: 100%;
}

.mp img {
    object-fit: contain;
}

.sp .map-shape {
    right: 0;
    z-index: 1;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
    height: 24rem;
    width: 85%;
    background-color: var(--primary_color);
    position: absolute;
    top: 100px;
    z-index: 1;
}


@media screen and (max-width: 951px) {
        .map-shape-wrapper {
        display: none;
    }
}


/* shape and map end */



/* search bar */


.sh-wrapper {
    background: #fff;
    margin: 5rem 0;
}

.sh-wrapper .sh-case {
    max-width: 70%;
    margin: auto;
}

.sh-case .sh {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #000 solid 2px;
    padding: 0 .5rem;
    padding-bottom: .8rem;
    width: 100%;
    position: relative;
}

.sh .sh-1 {
    width: 80%;
}

.sh .sh-1 .sh-tt {
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    border: none;
    width: 100%;
}

.sh .sh-1 .sh-tt:focus {
    outline: none;
}

.sh .sh-1 .sh-tt::-webkit-input-placeholder {
    color: #000;
    font-size: 1.375rem;
    font-weight: 700;
}

.sh .sh-1 .sh-tt::placeholder {
    color: #000;
    font-size: 1.375rem;
    font-weight: 700;
}

.sh .sh-2 {
    width: 20%;
    margin: auto;
}

.sh .sh-2 a {
    color: var(--primary_color);
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.sh .sh-2 a:hover {
    color: #000;
}

/* search bar end */





/* group heading */


.group-head-wrapper {
    width: 80%;
    margin: auto;
}

.group-head-case {
    margin: 4rem 0;
}

.group-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.g-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #000 solid 1px;
    padding-bottom: .6rem;
}

.g-head2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.g-head2 .gh-3 {
    margin-right: .4rem;
}


.g-head .gh-1 {
    font-size: 16px;
    color: #000;
    margin-right: .9rem;
}


/* group heading end */


.church-group-wrapper {
    margin: 5rem 0;
}

.church-group-case {
    width: 80%;
    margin: auto;
}

.church-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5rem;
    width: 100%;
}


.church-group .ch-gr {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
    grid-gap: 1em;
    gap: 1em;
    /* height: 350px; */
}

.ch-gr-1  {
    font-size: 18px;
    font-weight: 700;
    color: var(--primary_color);
}

.ch-gr-1:nth-child(2) {
    color: #000;
}

.ch-gr-2  {
    font-size: 16px;
    font-weight: 500;
    /* margin: .7rem 0; */
}

.ch-gr-3  {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary_color);
}

.ch-gr-3:hover  {
    color: #000;
    cursor: pointer;
}

.ch-gr-4 .linkAnima  {
    padding: 0;
    justify-content: flex-start;
    color: #000;
    /* margin-top: .7rem; */
    /* margin-bottom: 2rem; */
}
.ch-gr-4 .linkAnima:hover  {
    color: var(--primary_color);
}

.ch-gr-5  {
    background-color: #000;
    height: 2px;
    width: 90%;
    margin-top: 2rem;
}

@media screen and (max-width: 800px) {
    .church-group {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        grid-gap: 3em;
        gap: 3em;
    }

    .church-group .ch-gr {
        justify-content: center;
        width: 100%;
        margin: auto;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .church-group-case {
        width: 85%;
    }

    .ch-gr-5  {
    width: 80%;
    margin-top: 2rem;
}

}




/* individual church group */



/* second group dropdown list */


.group-dropdown-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 70%;
    margin: auto;
}

.group-dropdown {
    width: 400px; 
    /* margin: 100px auto; */
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}
.group-dropdown .dropdown-btn {
    padding: 10px;
    background: #fcfcfc;
    border-bottom: #000 solid 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.group-dropdown .dropdown-content {
    position: absolute;
    top: 110%;
    padding: 10px ;
    font-weight: 600;
    font-size: 17px;
    background: #fcfcfc;
    border: #000 solid 1px;
    color: #000; 
    width: 100%;   
}

.group-dropdown .dropdown-content .dropdown-item {
    padding: 10px; 
    cursor: pointer;
    transition: all .3s ease-in;
}

.group-dropdown .dropdown-content .dropdown-item:hover {
    background: var(--primary_color); 
    color: #fff;
}


/* second group dropdown list end */



/* group txt  */

.fel-grp {
    margin-bottom: 4rem;
}

.fel-grp .tt-3 {
    color: var(--primary_color);
    font-size: 2rem;
    font-weight: 700;
}
.fel-grp .tt-2 {
    font-size: 1.2rem;
    font-weight: 500;
}

/* group txt end */
.mapouter {
    max-width: 100%;
}

#gmap_canvas{
    max-width: 100%;
}
#new_showcase {
  background: url(/static/media/new-showcase.9a2e8bed.jpg) no-repeat center
    center/cover;
  height: auto;
  position: relative;
  /* padding: 1rem; */
}

#new_showcase:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

#new_showcase .showcase_wrapper {
  height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#new_showcase .showcase_wrapper h1 {
  font-size: var(--head_font);
  color: #fff;
  z-index: 1;
}

/* ======= SECTIOIN : EXPECT =======  */

#new_expect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  min-height: 450px;
}

#new_expect .left {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateY(-7rem);
          transform: translateY(-7rem);
}

#new_expect img {
  height: 350px;
}

#new_expect .right {
  width: 100%;
  padding-top: 3rem;
  -webkit-transform: translateY(-7rem);
          transform: translateY(-7rem);
}

#new_expect .right h2 {
  text-transform: uppercase;
}

#new_expect .right p {
  line-height: 1.5;
  margin: 0.5rem 0;
  max-width: 400px;
}

#new_expect .new_expect_links {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0.5rem 0;
}

#new_expect .right .linkAnima {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: left;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#new_expect .right .linkAnima:hover {
  color: var(--primary_color);
}

#new_expect .left .shape_2 {
  -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  height: 270px;
  width: 103%;
  background-color: var(--primary_color);
  position: absolute;
  left: 0;
  bottom: -3rem;
  z-index: -1;
}

/* ======= section : NEW_ABOUT ======= */

#new_about .new_about_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  min-height: 450px;
}

#new_about .right {
  width: 100%;
  height: 100%;
  position: relative;
  /* transform: translateY(-7rem); */
}

#new_about img {
  height: 350px;
}

#new_about .left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* padding-top: 3rem; */
  /* transform: translateY(-7rem); */
}

#new_about .left h2 {
  text-transform: uppercase;
}

#new_about .left p {
  line-height: 1.5;
  margin: 0.5rem 0;
  max-width: 400px;
}

#new_about .new_expect_links {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0.5rem 0;
}

#new_about .left .linkAnima {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: left;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#new_about .left .linkAnima:hover {
  color: var(--primary_color);
}

#new_about .right .shape_2 {
  /* clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%); */
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 23% 99%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 23% 99%);
  height: 270px;
  width: 103%;
  background-color: var(--primary_color);
  position: absolute;
  right: 0;
  bottom: -3rem;
  z-index: -1;
}

.am_new_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}
/* 
.am_new_btn li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.am_new_btn .new_submit {
  border-radius: 0;
  font-size: 5rem;
} */
#contact_form .form_wrapper .am_new_btn input[type="submit"] {
  background-color: #fff;
  width: 150px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  border: 1px solid var(--primary_color);
  background-color: var(--primary_color);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: all var(--transition);
}

#contact_form .form_wrapper input[type="submit"]:hover {
  background-color: #fff;
  color: #000;
}

#map {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#map .ask {
  margin-bottom: 3rem;
}

#map a {
  color: var(--primary_color);
  font-weight: 600;
  line-height: 1.3;
  padding: 1rem;
}

#map a:hover {
  color: #fff;
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {
  /* ======== Expeect Section ======= */
  #new_expect {
    max-width: 85%;
    margin: auto;
    flex-direction: column;
  }

  #new_expect .left {
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }

  #new_expect .right {
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }

  #new_expect .right p {
    max-width: 100%;
  }

  #new_expect img {
    height: auto;
  }

  #new_expect .left .shape_2 {
    left: -10%;
    bottom: -2rem;
    width: 118%;
  }

  /* ======= SECTION: EXPECT_ABOUT ======= */
  #new_about .new_about_wrapper {
    max-width: 85%;
    margin: auto;
    flex-direction: column-reverse;
  }

  #new_about img {
    height: auto;
  }

  #new_about .left p {
    max-width: 100%;
  }

  #new_about .right .shape_2 {
    left: -10%;
    bottom: -2rem;
    width: 118%;
  }
}

/* ======= Max-width: 600px ======= */

@media screen and (max-width: 600px) {
  /* ======== Expeect Section ======= */
  #new_expect {
    max-width: 100%;
    padding: 1rem;
  }
  #new_expect .right p {
    max-width: 100%;
  }

  #new_expect .left .shape_2 {
    -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
    height: 70%;
    bottom: -1rem;
  }

  /* ======= SECTION: EXPECT_ABOUT ======= */
  #new_about .new_about_wrapper {
    max-width: 100%;
    padding: 1rem;
  }
  #new_about .left p {
    max-width: 100%;
  }

  #new_about .right .shape_2 {
    -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
    height: 70%;
    bottom: -1rem;
  }
}

#faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  margin-top: 2rem;
}

#faq .faq_wrapper {
  width: 70%;
  margin-top: 2rem;
  /* padding-bottom: 3rem; */
}

.faq_wrapper h1 {
  font-size: 2.5rem;
  line-height: 1;
  margin-top: 1rem;
  text-transform: capitalize;
}

/* .faq_wrapper .accordion {
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
}

.faq_wrapper .accordion p {
  font-size: 1.2rem;
}

.faq_wrapper .question {
  font-weight: 700;
  padding: 15px 0;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq_wrapper .question span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  transition: transform var(--transition);
}

.faq_wrapper .question .activated {
  transform: rotate(180deg);
}

.faq_wrapper .question p {
  transition: color var(--transition);
}

.faq_wrapper .question:hover p,
.faq_wrapper .question:focus p {
  color: var(--primary_color);
}

.faq_wrapper .accordion .dropdown {
  transition: all 300ms cubic-bezier(0, 1, 0, 1);
}

.faq_wrapper .accordion .dropdown.drop {
  height: 0;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0, 1, 0, 1);
}

.faq_wrapper .accordion .dropdown p {
  padding: 10px 20px 10px 0;
  font-size: 1rem;
} */

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
  #faq .faq_wrapper {
    padding: 0 2rem;
    width: 100%;
  }

  .faq_wrapper h1 {
    font-size: 2rem;
  }
}

/* showcase */

.min-group-showcase {
  background: url(/static/media/x1.2d0bcaa5.jpeg) no-repeat center center/cover;
  height: 100vh;
}

.min-group-case {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.min-group-txt {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
    .min-group-showcase {
        height: 70vh;
    }
} */

/* showcase end */

/* kids cards section */

.kids-cards-wrapper {
  background: #fff;
  position: relative;
}

.kids-cards-case {
  max-width: 85%;
  margin: auto;
}

.kids-cards-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kids-cards-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  width: 31%;
  transition: all 0.3s ease-in-out;
}

.kdt {
  width: 100%;
}

.kc-a {
  background: url(/static/media/x2.4869c1f2.jpeg) no-repeat center center/cover;
  /* background: url('../../assets/images/tddd1.jpg') no-repeat center center/cover; */
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in;
}
.kc-a2 {
  background: url(/static/media/x3.2a5c2ad0.jpeg) no-repeat center center/cover;
  /* background: url('../../assets/images/tddd2.jpg') no-repeat center center/cover; */
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in;
}
.kc-a3 {
  background: url(/static/media/x4.471175a4.jpeg) no-repeat center center/cover;
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in-out;
}

.kk-s {
  -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background: var(--primary_color);
  height: 3rem;
  width: 60%;
  position: absolute;
  top: 2rem;
  left: 0;
}

/* .kids-cards-1:hover .kk-s-over {
    background: rgba(208, 47, 47, 0.6);
    height: 100%;
    transition: all .3s ease-in;
} */

.kk-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 2.5rem;
  left: 10px;
}

.kc-b {
  background: #414141;
  height: 16rem;
  width: 100%;
  position: relative;
  top: -6.5rem;
}

.kc-b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.kk-txt-1 {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2;
  margin-bottom: 1rem;
}

.kk-txt-2 {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--primary_color);
}

/* .kids-cards-1:hover .kc-b {
    background: var(--primary_color);
    transition: all .2s ease-in;
}

.kids-cards-1:hover .kk-txt-2 {
    color: #fff;
    transition: all .2s ease-in;
} */

@media screen and (max-width: 950px) {
  .kids-cards-show {
    flex-direction: column;
    justify-content: center;
  }

  .kids-cards-1 {
    width: 100%;
    margin-bottom: 2rem;
  }

  /* .kk-s {
        width: 50%;
    } */
}

@media screen and (max-width: 600px) {
  .kk-s {
    width: 70%;
  }

  .kk-txt-2 {
    font-size: 1.5rem;
  }
}

/* kids cards section end */

/* kids outreach */

.kid-out-wrapper {
  margin-bottom: 5rem;
}

.kids-out-show {
  max-width: 85%;
  margin: auto;
}

.kids-out {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kids-aa {
  width: 65%;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
}

.ka-case {
  background: url(/static/media/glll.a1fdf716.jpg) no-repeat center center/cover;
  height: 22rem;
  position: relative;
}

.ka-s {
  -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background: var(--primary_color);
  height: 3rem;
  width: 45%;
  position: absolute;
  top: 2rem;
  left: 0;
}

.ka-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 2.5rem;
}

/* .kids-out:hover .ka-over {
    background: rgba(208, 47, 47, 0.6);
    height: 100%;
    transition: all .2s ease-in;
} */

.kids-bb {
  width: 35%;
  background: #414141;
  height: 22rem;
}

.kb-show {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* cursor: pointer; */
}

.kb-txt-1 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.kb-txt-2 {
  color: var(--primary_color);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

/* .kids-out:hover .kids-bb {
    background: var(--primary_color);
    transition: all .2s ease-in;
}
.kids-out:hover .kb-txt-2 {
    color: #fff;
    transition: all .2s ease-in;
} */

@media screen and (max-width: 950px) {
  .kids-out {
    flex-direction: column;
  }

  .kids-aa {
    width: 100%;
  }
  .kids-bb {
    width: 100%;
    height: 15rem;
  }

  .kb-txt-2 {
    font-size: 25px;
  }

  .ka-s {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .kb-txt-2 {
    font-size: 22px;
  }

  .ka-s {
    width: 65%;
  }
}

/* kids outreachend */

/* select campus  */

.sel-cam-wrapper {
  margin-bottom: 7rem;
}

.sel-cam-case {
  max-width: 85%;
  margin: auto;
}

.sel-cam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sc-a {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
}
.sc-b {
  font-size: 50px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .sc-b {
    font-size: 30px;
  }
}

/* select campus end */

/* kids with special needs  */

.special-wrapper {
  margin-bottom: 6rem;
}

.special-case {
  max-width: 85%;
  margin: auto;
}

.special {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.spec-a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  /* margin-bottom: 1rem; */
  text-align: center;
}

.spec-b {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  /* margin-bottom: 1rem; */
  text-align: left;
  width: 100%;
}

.spec-c .linkAnima {
  color: #000;
  padding: 0;
  justify-content: flex-start;
  font-weight: 700;
}
.spec-c .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 600px) {
  .spec-a {
    font-size: 22px;
  }
}

/* kids with special needs end */

/* ministry events */

.min-eve-wrapper {
  max-width: 85%;
  margin: auto;
}

.min-eve-txt {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  text-align: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 800px) {
  .min-eve-txt {
    font-size: 30px;
  }
}

/* ministry events end */

/* seminar card  */

.seminar-wrapper {
  width: 100%;
  margin: auto;
}

.seminar-case {
  margin-bottom: 12rem;
}

.seminar {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  position: relative;
  width: 100%;
}

.sem-a {
  -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  height: 270px;
  width: 30%;
  background-color: var(--primary_color);
  position: absolute;
  left: 0;
  top: 6rem;
}

.sem-b img {
  object-fit: cover;
  border-radius: 7px;
  width: 520px;
}
.sem-b img:hover {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
  z-index: 4;
  cursor: pointer;
}

.sem-b {
  position: absolute;
  /* left: 10rem; */
  left: 10%;
  top: 0.2rem;
}

.sem-c {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px 14px rgba(0, 0, 0, 0.42);
  position: relative;
  right: -11rem;
  top: 2rem;
  /* width: 500px; */
  width: 35%;
  height: 290px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sem-c:hover {
  scale: 1.1;
  transition: all 0.2s ease-in-out;
}

.sem-c-1 {
  background: #efefef;
  width: 90%;
  height: 100%;
}
.sem-c-2 {
  background: rgb(255, 255, 255);
  width: 10%;
  height: 100%;
}

/* .sem-c-txt-1 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
}

.sem-c-txt-2 {
  font-size: 18px;
  font-weight: 600;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
} */

.sem-c-txt-3 {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2rem;
  margin-right: 1rem;
  -webkit-transform: translateY(170%);
          transform: translateY(170%);
}

.sem-c-txt-4 .linkAnima {
  padding: 0;
  justify-content: flex-start;
  color: #000;
  margin-left: 2rem;
  margin-top: 1rem;
}
.sem-c-txt-4 .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 960px) {
  .seminar {
    flex-direction: column;
    justify-content: center;
  }

  .seminar-case {
    margin-bottom: 27rem;
  }

  .sem-c {
    right: -8rem;
    top: 19rem;
    width: 65%;
    /* flex-direction: column; */
  }

  .sem-b img {
    width: 80%;
  }
}

@media screen and (max-width: 895px) {
  .sem-c {
    right: -9rem;
    top: 22rem;
    width: 60%;
    /* flex-direction: column; */
  }
}

@media screen and (max-width: 761px) {
  .sem-c {
    right: -7rem;
    top: 18rem;
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .sem-c {
    right: -5.4rem;
    top: 17rem;
    width: 70%;
    height: 250px;
  }

  .sem-c-txt-3 {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }

  .sem-b img {
    width: 86%;
  }

  .sem-a {
    width: 60%;
    height: 130px;
  }
}

@media screen and (max-width: 600px) {
  .seminar-case {
    margin-bottom: 14rem;
  }

  .sem-c {
    right: -3.3rem;
    top: 10rem;
    width: 70%;
    height: 250px;
  }

  .sem-b img {
    width: 86%;
  }

  .sem-a {
    width: 60%;
    height: 130px;
  }
}

/* seminar card end  */

/* ministry director  */

.min-dir-case {
  width: 100%;
  background: #efefef;
  margin-bottom: 5rem;
}

.min-dir {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 400px; */
}

.mindir-a {
  width: 45%;
}

.mindir-a img {
  object-fit: cover;
  -webkit-clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  width: 100%;
  /* height: 100%; */
  height: 500px;
}

.mindir-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
}

.dir-a {
  font-size: 2rem;
  color: #000;
  font-weight: 700;
}

.dir-b {
  /* font-size: 1.3rem; */
  color: #000;
  font-weight: 600;
  margin: 1rem 0;
}

.dir-c .linkAnima {
  color: #000;
  padding: 0;
  justify-content: flex-start;
  font-weight: 700;
}

.dir-c .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 950px) {
  .min-dir-case {
    width: 85%;
    margin: auto;
    background: #fff;
  }

  .min-dir {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mindir-a {
    width: 100%;
    height: 300px;
  }
  .mindir-b {
    width: 100%;
    background: #efefef;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
  }
}

/* ministry director end */

.leadership-pagepage {
  background: #fff;
}

/* SHOWCASE */

.leadership-showcase {
  /* background: url('../../assets/images/abthh.jpg') no-repeat center center/cover; */

  background: url(/static/media/alexdocas22.01f8f8f9.png) no-repeat center
    center/cover;
  height: 100vh;
}

.leadership-show {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.leadership-text {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
    .leadership-showcase {
    height: 70vh;
}
} */

/* SHOWCASE END */

/* .leadd info  */

.leadd-info-case {
  padding-top: 4rem;
}

.leadd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.leadd .tt-3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: var(--primary_color);
  text-transform: uppercase;
}

.leadd .tt-2 {
  color: #000;
  font-size: 17px;
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
}

/* @media screen and (max-width: 600px) {
    .leadd .tt-3 {
    font-size: 18px;
}
.leadd .tt-2 {
    font-size: 16px;
}

.leadd-info-case {
    padding-top: 2rem;
}
} */

/* .leadd info end */

/* LEADERS */

#leade-wrapper {
  background: #fff;
  width: 100%;
}

.leade-case {
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.lead-flex-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  width: 85%;
  margin: 3rem auto;
}

.lead-flex {
  width: 350px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 0 1.3rem; */
  height: auto;
}

.lf1-pic {
  margin-bottom: 0.9rem;
  width: 100%;
  /* height: 150px; */
}

.lf1-pic22 {
  margin-bottom: 0.9rem;
  width: 80%;
  /* height: 150px; */
}

.lf1-pic img {
  /* object-fit: contain; */
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  /* width: 300px; */
  height: 320px;
}

.lf1-pic22 img {
  /* object-fit: contain; */
  object-fit: contain;
  border-radius: 5px;
  /* width: 100%; */
  /* width: 300px; */
  height: 320px;
}

.lf12 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 0.5rem 0;
  text-align: center;
  width: 100%;
}
.lf3 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
  width: 100%;
}

@media screen and (max-width: 850px) {
  .lead-flex-show {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* margin: 3rem 0; */
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .leade-case {
    padding: 0;
  }

  .lead-flex {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1.3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .lf1-pic {
    margin-bottom: 0.9rem;
    width: 70%;
    /* height: 150px; */
  }

  .lf1-pic img {
    object-fit: cover;
  }

  .lf1-pic22 {
    margin-bottom: 0.9rem;
    width: 70%;
    /* height: 150px; */
  }

  .lf1-pic22 img {
    object-fit: contain;
  }
}

/* LEADERS END */

/* ALONE */

.alone-wrapper {
  /* margin-top: 11rem;
    margin-bottom: 13rem;  */
  margin-top: 11%;
  margin-bottom: 15%;
}

.alone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alone-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  margin-left: 10%;
}

/* @media screen and (max-Width:900px) {
    .alone-1 {
    width: 40%;
    margin-left: 2%;
}
} */

.lone-1 {
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .lone-1 {
    font-size: 20px;
  }
}

.lone-2 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 1rem 0;
}

.lone-3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.lone-4 a {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: var(--primary_color);
}
.lone-4 a:hover {
  color: #000;
}

.alone-2 {
  width: 60%;
  margin-left: 3rem;
}

.alone-2 {
  position: relative;
  right: 0;
}

.alone-2 .pic img {
  object-fit: cover;
  display: block;
}

.alone-2 .pic {
  z-index: 3;
  /* width: 700px; */
  width: 95%;
  /* height: 10px!important; */
  position: absolute;
  right: 0;
  bottom: -235px;
}

.shape-2 {
  top: 10px;
  right: 0;
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 17rem;
  width: 100%;
  background-color: var(--primary_color);
  position: absolute;
}

/* media quwery */

@media screen and (max-width: 950px) {
  .alone-wrapper {
    max-width: 80%;
    margin: auto;
    padding-top: 5rem;
    padding-bottom: 7rem;
  }

  .alone {
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    margin: auto;
  }

  .alone-1 {
    width: 100%;
    margin: auto;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .alone-2 {
    width: 100%;
    margin-left: 0;
    margin: auto;
  }

  .alone-2 .pic {
    z-index: 3;
    width: 90%;
    margin: auto;
    position: relative;
    bottom: 0;
  }

  .shape-2 {
    top: 230px;
    right: 0;
    z-index: 1;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
    height: 12rem;
    width: 100%;
    background-color: var(--primary_color);
    position: absolute;
  }
}

/* media query */

/* media quwery */

@media screen and (max-width: 949px) {
  .alone-wrapper {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }

  .shape-2 {
    top: 300px;
  }
}

@media screen and (max-width: 900px) {
  .alone-wrapper {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }

  .shape-2 {
    top: 270px;
  }
}
@media screen and (max-width: 865px) {
  .alone-wrapper {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }

  .shape-2 {
    top: 240px;
  }
}

@media screen and (max-width: 800px) {
  .shape-2 {
    top: 210px;
  }
}

@media screen and (max-width: 700px) {
  .shape-2 {
    top: 150px;
  }
}

@media screen and (max-width: 600px) {
  .alone-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .shape-2 {
    top: 100px;
    height: 100px;
  }
}

/* media query */

/* ALONE END */

/* LEADERS END */

#mailto-but button {
    margin: 0;
}


/* mail  */

.mall-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mall-btn .m-ico {
    color: #000;
}

.mall-btn .m-ico:hover {
    color: #fff;
}

.mall-btn .m-ico {
    margin-left: .5rem;
    border-radius: 50%;
    border: #000 solid 2px;
    padding: 2px 5px;
    padding-bottom: 1px;
    padding-top: 3px;
    transition: all .3s ease-in-out;
}

.mall-btn.m-ico:hover {
    border: var(--primary_color) solid 2px;
    transition: all .3s ease-in-out;
}

/* mail end */
a {
    cursor: pointer;
    text-decoration: none;
}

.location-page {
     background: #fff;
     padding-top: 3rem;
}

.loc-wrapper {
    /* background: #fff; */
    max-width: 85%;
    margin: auto;
}


@media screen and (max-width: 600px) {
.loc-wrapper {
    max-width: 90%;
    margin: auto;
}
}

.loc-wrapper .loc {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
}

.loc-wrapper .loc .loc-1 {
    font-size: var(--head_font);
    font-weight: 700;
    color: #B5192A;
}


.loc-wrapper .loc .loc-2 a {
    /* font-size: 16px; */
    font-weight: 700;
    justify-content: flex-start;
    padding: 0;
    padding-top: 1rem;
    color: #000;
}

@media screen and (max-width: 950px) {
 .loc-wrapper .loc .loc-1 {
    font-size: 50px;
    text-align: center;
} 
.loc-wrapper .loc .loc-2 {
    text-align: center;
    max-width: 80%;
    margin: auto;
    font-size: 30px;
}
}

@media screen and (max-width: 600px) {
 .loc-wrapper .loc .loc-1 {
    font-size: 30px;
}

.loc-wrapper .loc .loc-2 {
    font-size: 25px;
    padding-top: 1rem;
}
}

.container {
    max-width: 85%;
    margin: auto;
}

/* campus styles */


.campus-wrapper  {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    grid-gap: 3em;
    gap: 3em;
    padding-bottom: 5rem;
    margin-top: 2rem;
    }

.campus-wrapper .campus-1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 3em;
    gap: 3em;
    /* margin-top: 2rem; */
}

@media screen and (max-width: 950px) {
.campus-wrapper .campus-1 {
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    grid-gap: 2em;
    gap: 2em;
    max-width: 100%;
    margin: auto;
}
.campus-wrapper {
    margin-top: 3rem;
}

}

.campus-wrapper .campus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: #000 solid 2px;
    width: 350px;
    /* height: 420px; */
    height: 320px;

}

@media screen and (max-width: 850px) {
    .campus-wrapper .campus {
    /* height: 750px; */
    border-bottom: 100%;
    width: 100%;
    margin: auto;
}
}


@media screen and (max-width: 950px) {
   .campus-wrapper .campus {
    /* height: 600px; */
    border-bottom: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 3rem;
}

}

@media screen and (min-width: 851px) {
   .campus-wrapper .campus {
    /* height: 850px; */
    /* height: 850px; */
    border-bottom: 100%;
    /* width: 90%;
    margin: auto; */
    margin-bottom: 1rem;
}

}

.city {
    font-size: 30px;
    font-weight: 700;
    color: #000;
}

.city-1 {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    margin-bottom: 2rem;
}

.broad-1 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    /* margin-bottom: 5rem; */
    /* margin: 1.5rem 0; */
}

.add {
    /* font-size: 25px; */
    font-weight: 600;
    color: #B5192A;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.add:hover {
    /* background: #B5192A; */
    color: #000;
    padding: 1.5rem auto;
}

.ser {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    /* margin-top: 1.5rem; */
    /* margin-bottom: 1.5rem; */
}

.day {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0.5rem 0;
    /* margin-bottom: 1.5rem; */
}

.day-1 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0.75rem;
    /* margin-bottom: 2rem; */
}

.info a {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    justify-content: flex-start;
      /* margin-top: 1.5rem; */
      padding: 0;
    margin-bottom: 1.5rem;
}
/* 
.info a:hover {
    color: #B5192A;
} */

#message_showcase {
  height: 37rem;
}

#message_showcase .showcase_wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(/static/media/message.a3e71325.jpg) no-repeat center
    center/cover;
}

#message_showcase .showcase_wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#message_showcase .showcase_wrapper p {
  font-size: 1.7rem;
  font-weight: 700;
  color: #fff;
  position: relative;
  padding: 0 0.5rem;
}

#message_showcase .showcase_wrapper h2 {
  font-size: 3.5rem;
  color: #fff;
  position: relative;
  line-height: 1.3;
}

/* ======= MESSAGE_MESSAGES SECTION ======= */
#message_messages {
  background: #000;
  min-height: 40rem;
  padding: 1rem;
}

#message_messages .messages_wrapper {
  max-width: 1000px;
  margin: 3rem auto;
  height: 100%;
}

#message_messages .messages_wrapper .search {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#message_messages .messages_wrapper .search span {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 1.3rem;
  padding-left: 0.5rem;
  pointer-events: none;
}

#message_messages .messages_wrapper .search input {
  width: 100%;
  color: #fff;
  font-size: 1.2rem;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: 0;
  border-bottom: 1px solid #f4f4f4;
}

#message_messages .messages_wrapper .search input:focus ~ span {
  display: none;
}

#message_messages .messages_wrapper .search .search_icon {
  position: absolute;
  right: 0;
  color: var(--primary_color);
  font-size: 2rem;
  cursor: pointer;
}

#message_messages .filter {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 2rem 0;
  color: #fff;
}

#message_messages .message_card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  position: relative;
  margin: 1rem 0;
}

#message_messages .message_card a {
  background-color: rgb(43, 43, 43);
  transition: all var(--transition);
}

#message_messages .message_card a:hover {
  background-color: var(--primary_color);
}

#message_messages .message_card a:hover .bottom p {
  color: #fff;
}

#message_messages .message_card img {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}

#message_messages .message_card .bottom {
  padding: 1rem;
}

#message_messages .message_card .bottom p {
  color: #fff;
  transition: all var(--transition);
}

#message_messages .message_card .bottom p:first-child {
  text-transform: uppercase;
  font-weight: 500;
}

#message_messages .message_card .bottom p:last-child {
  color: var(--primary_color);
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: capitalize;
}

#message_messages .filter_option p {
  color: #fff;
}

#message_messages .message_main .content {
  display: flex;
  flex-wrap: wrap;
}

#message_messages .pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  list-style: none;
  color: var(--primary_color);
}

#message_messages .pagination .page-item .page-link {
  padding: 0.5rem 0.75rem;
  list-style: none;
  color: var(--primary_color);
}

#message_messages .pagination .page-item .page-link:hover,
#message_messages .pagination .page-item .page-link:focus {
  background-color: var(--primary_color);
  color: #fff;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 900px) {
  #message_showcase .showcase_wrapper h2 {
    font-size: 3rem;
  }

  #message_messages .filter {
    flex-direction: column;
  }

  #message_messages .message_card {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  #message_showcase .showcase_wrapper h2 {
    font-size: 2.3rem;
    line-height: 1.3;
  }

  #message_messages .message_card {
    grid-template-columns: 1fr;
  }

  #message_messages .pagination {
    padding: 0;
    text-align: center;
    line-height: 1;
  }
}

/* .main_pag {
  height: 100vh;
}

.main_pag .search {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.main_pag .content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
} */

#ministry-page {
  background: #fff;
}

/* showcase */
.ministry-wrapper {
  background: url(/static/media/xc6.e39b6c6a.jpeg) no-repeat center center/cover;
  height: 100vh;
}

.ministry-case {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.ministry-text {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
    .ministry-wrapper {
    height: 70vh;
}

} */

/* showcase end */

/* where */

.where-wrap {
  background: #fff;
  margin-bottom: 8rem;
}

/* .where-show {
    margin: 8rem;
} */

.where-case {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.where-11 {
  font-size: 19px;
  color: #000;
  font-weight: 600;
  width: 40%;
  margin-left: 8%;
}

.where-11 .w-11-1 {
  font-size: 30px;
  margin: 1.5rem 0;
}

.where-22 {
  width: 60%;
}

.where-22 .w-shape {
  right: 0;
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 13rem;
  width: 100%;
  background: var(--primary_color);
}

.where-22 .w-pic {
  width: 50%;
  height: 250px;
  position: absolute;
  right: 0;
  top: -80px;
  background: #d9d9d9;
}

/* .where-22 .w-pic img {
    object-fit: cover;
    height: 400px;
} */

.where-22 .w-tt {
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  position: absolute;
  /* right: 250px; */
  right: 15%;
  top: 1px;
}

.popup-box .box {
  width: 54%;
  margin-top: calc(100vh - 87vh - 20px);
  max-height: 89vh;
  overflow-y: hidden;
}

.popup-box .close-icon {
  right: calc(24.3% - 30px);
  top: calc(100vh - 87vh - 33px);
  color: var(--primary_color);
}

.form-group {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .where-22 .w-tt {
    right: 10%;
  }
}

@media screen and (max-width: 950px) {
  .where-22 .w-tt {
    right: 9%;
    font-size: 1.7rem;
  }

  .where-11 .w-11-1 {
    /* font-size: 30px; */
    margin-top: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .where-22 .w-tt {
    right: 9%;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 749px) {
  .where-case {
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 80%;
    margin: auto;
  }

  .where-22 {
    width: 100%;
    margin: auto;
  }

  .where-11 {
    width: 100%;
    margin: auto;
    margin-top: 13rem;
  }

  .where-22 .w-pic {
    width: 90%;
    height: 200px;
    right: 18px;
    top: -40px;
    z-index: 3;
  }

  .where-22 .w-shape {
    right: 0;
    height: 13rem;
    top: -90px;
    position: absolute;
    z-index: 1;
    width: 97%;
  }

  .where-22 .w-tt {
    font-size: 1.5rem;
    /* right: 250px; */
    right: 25%;
    top: 10px;
    z-index: 4;
  }
}

@media screen and (max-width: 600px) {
  .where-22 .w-tt {
    right: 20%;
    font-size: 1.3rem;
    top: 0;
  }

  .where-22 .w-pic {
    width: 90%;
    height: 130px;
    right: 18px;
    top: -40px;
    z-index: 3;
  }

  .where-22 .w-shape {
    right: 0;
    height: 7rem;
    top: -65px;
    position: absolute;
    z-index: 1;
    width: 97%;
  }

  .where-11 {
    width: 100%;
    margin: auto;
    margin-top: 10rem;
  }
}

/* where end */

/* MIN SEC */

.min-sec-wrapper {
  max-width: 85%;
  margin: auto;
}

.min-sec-case .min-sec {
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
  /* gap: 2em; */
}

.min-sec-case .min-sec .minsec {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  justify-content: space-between;
  align-items: flex-start;
  width: 45%;
  /* height: 620px; */
  grid-gap: 1.5em;
  gap: 1.5em;
}

.minsec .min-1 {
  height: 320px;
  width: 100%;
}

.minsec .min-1 img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.minsec .min-2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  /* margin: .7rem 0; */
}

.minsec .min-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.minsec .min-3 .linkAnima {
  padding: 0;
  justify-content: flex-start;
  color: #000;
}

.minsec .min-3 .linkAnima:hover {
  color: var(--primary_color);
}

.minsec .min-3 .linkAnima:nth-child(1) {
  margin-right: 2.5rem;
}

.minsec .min-4 {
  background: #000;
  height: 2px;
  width: 100%;
  /* margin-top: 3rem; */
}

@media screen and (max-width: 750px) {
  .min-sec-case .min-sec {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .min-sec-case .min-sec .minsec {
    margin-bottom: 3.5rem;
    width: 90%;
  }

  .where-11 .w-11-1 {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .minsec .min-1 {
    height: 200px;
    width: 100%;
  }
}

/* MIN SEC END */
/* row */
.row {
    --gutter-x: 1.5rem;
    --gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--gutter-y));
    margin-right: calc(-0.5 * var(--gutter-x));
    margin-left: calc(-0.5 * var(--gutter-x));
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) * 0.5);
    padding-left: calc(var(--gutter-x) * 0.5);
    margin-top: var(--gutter-y);
}

/* column */
.col {
    flex: 1 0;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

/******* Mobile Screens ********/
@media (min-width: 576px) {
    .col-sm {
        flex: 1 0;
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

/******* Tablet Screens ********/
@media (min-width: 768px) {
    .col-md {
        flex: 1 0;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

/******* Desktop Screens ********/
@media (min-width: 992px) {
    .col-lg {
        flex: 1 0;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

/******* Large Desktop Screens ********/
@media (min-width: 1200px) {
    .col-xl {
        flex: 1 0;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

/******* Image ********/
.img-fluid {
    max-width: 100%;
    height: auto;
}

/******* Item Ordering *******/
.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

/******* Item Ordering Mobile Screens *******/
@media (min-width: 576px) {
    .order-sm-1 {
        order: 1 !important;
    }

    .order-sm-2 {
        order: 2 !important;
    }

    .order-sm-3 {
        order: 3 !important;
    }

    .order-sm-4 {
        order: 4 !important;
    }

    .order-sm-5 {
        order: 5 !important;
    }
}

/******* Item Ordering Tablet Screens *******/
@media (min-width: 768px) {
    .order-md-1 {
        order: 1 !important;
    }

    .order-md-2 {
        order: 2 !important;
    }

    .order-md-3 {
        order: 3 !important;
    }

    .order-md-4 {
        order: 4 !important;
    }

    .order-md-5 {
        order: 5 !important;
    }
}

/******* Item Ordering Desktop Screens *******/
@media (min-width: 992px) {
    .order-lg-1 {
        order: 1 !important;
    }

    .order-lg-2 {
        order: 2 !important;
    }

    .order-lg-3 {
        order: 3 !important;
    }

    .order-lg-4 {
        order: 4 !important;
    }

    .order-lg-5 {
        order: 5 !important;
    }
}

/******* Item Ordering Large Desktop Screens *******/
@media (min-width: 1200px) {
    .order-xl-1 {
        order: 1 !important;
    }

    .order-xl-2 {
        order: 2 !important;
    }

    .order-xl-3 {
        order: 3 !important;
    }

    .order-xl-4 {
        order: 4 !important;
    }

    .order-xl-5 {
        order: 5 !important;
    }
}

/***** Margin classes ******/
.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.ml-6 {
    margin-left: 6rem !important;
}

.ml-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .mt-lg-0 {
        margin-top: 0 !important;
    }

    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mt-lg-3 {
        margin-top: 1rem !important;
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mt-lg-5 {
        margin-top: 3rem !important;
    }

    .mt-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-0 {
        margin-right: 0 !important;
    }

    .mr-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mr-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mr-lg-3 {
        margin-right: 1rem !important;
    }

    .mr-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mr-lg-5 {
        margin-right: 3rem !important;
    }

    .mr-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-0 {
        margin-bottom: 0 !important;
    }

    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }

    .mb-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-0 {
        margin-left: 0 !important;
    }

    .ml-lg-1 {
        margin-left: 0.25rem !important;
    }

    .ml-lg-2 {
        margin-left: 0.5rem !important;
    }

    .ml-lg-3 {
        margin-left: 1rem !important;
    }

    .ml-lg-4 {
        margin-left: 1.5rem !important;
    }

    .ml-lg-5 {
        margin-left: 3rem !important;
    }

    .ml-lg-auto {
        margin-left: auto !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }

    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .pt-lg-0 {
        padding-top: 0 !important;
    }

    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pt-lg-3 {
        padding-top: 1rem !important;
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pt-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-0 {
        padding-right: 0 !important;
    }

    .pr-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pr-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pr-lg-3 {
        padding-right: 1rem !important;
    }

    .pr-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pr-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-0 {
        padding-bottom: 0 !important;
    }

    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-0 {
        padding-left: 0 !important;
    }

    .pl-lg-1 {
        padding-left: 0.25rem !important;
    }

    .pl-lg-2 {
        padding-left: 0.5rem !important;
    }

    .pl-lg-3 {
        padding-left: 1rem !important;
    }

    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }

    .pl-lg-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
}

/***** Padding classes ******/
.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.px-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

@media (min-width: 576px) {
    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
}

@media (min-width: 768px) {
    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
}

@media (min-width: 992px) {
    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .pl-lg-1 {
        padding-left: 0.25rem !important;
    }

    .pl-lg-2 {
        padding-left: 0.5rem !important;
    }

    .pl-lg-3 {
        padding-left: 1rem !important;
    }

    .pl-lg-4 {
        padding-left: 1.5rem !important;
    }

    .pl-lg-5 {
        padding-left: 3rem !important;
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    .px-lg-6 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
}

@media (min-width: 1200px) {
    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
}

/********* Text Utilities *********/
.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

/****** Flex Box Utilities ********/
.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-none {
    display: none !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-none {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-none {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .d-sm-block {
        display: block !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-none {
        display: none !important;
    }
}

/****** Positioning ********/
.position-relative {
    position: relative !important;
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

/********** Card ************/
.card {
    position: relative;
    display: -ml-flexbox;
    display: flex;
    -ml-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-body {
    -ml-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

/******** Height Utilities ********/
.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

/********* Form Utilities **********/
.form-control {
    display: block;
    width: 100%;
    padding: 0.475rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b5192a;
    outline: 0;
    /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em;
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
    z-index: 5;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.input-group .btn:focus {
    z-index: 5;
}
.out-showcase {
    background: url(/static/media/gpo3.b6ff16d4.jpeg) no-repeat center center/cover;
    height: 100vh;
}

.out-case {
    background: rgba(0, 0, 0, .6);
    height: 100%;
}

.out-text {
    color: #fff;
    text-align: center;
    font-weight: 700;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%)
}

/* @media screen and (max-width: 600px) {
    .out-showcase {
    height: 70vh;
}

} */


/* local outreach  */

.local-wrapper {
    background: #fff;
    margin: 5rem 0;
}

.local-case {
    max-width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.local-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.ll-1 {
    color: var(--primary_color);
    border-bottom: var(--primary_color) solid 2px;
    padding: 0 .5rem;
    margin-right: 4rem;
}

.ll-2 {
    color: #000;
    border-bottom: #000 solid 2px;
    padding: 0 .5rem;
}

.local-2 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary_color);
    margin: 2rem 0;
    text-align: center;
    text-transform: uppercase;
}

.local-3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    text-align: left;
    margin-bottom: 1.5rem;
}

.local-4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 3.5rem;
}

.local-5 {
    background-color: var(--primary_color);
    height: 2px;
    width: 100%;
}


@media screen and (max-width: 600px) {
    
.local-2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary_color);
    margin: 2rem 0;
    text-align: center;
}

.local-3 {
    font-size: 16px;
}

.local-4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 3.5rem;
}
}

/* local outreach end */



/* outreach groups sponsors */


.out-spo-wrapper {
    background: #fff;
}

.out-spo-case {
    width: 85%;
    margin: auto;
}

.out-sop-show {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    width: 100%;
    margin-bottom: 2rem;
}

.out-sop {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: center;
    height: 400px;
}

/* .sop-1  {
    width: 250px;
    height: 100px;
} */

.sop-1 img {
    object-fit: contain;
    width: 80%;
    margin: auto;
    height: 200px;
}

.sop-2 {
    color: var(--primary_color);
    font-size: 16px;
    cursor: pointer;
    margin: 1rem 0;
}
.sop-2:hover {
    color: #000;
}

.sop-3 {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.sop-4 {
    font-size: 1.5rem;
    color: var(--primary_color);
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    margin: .6rem 0;
}

.out-sop .linkAnima {
    justify-content: flex-start;
    padding: 0;
    color: #000;
}
.out-sop .linkAnima:hover {
    color: var(--primary_color);
}

.sop-6 {
    background: #000;
    width: 100%;
    height: 2px;
    margin-top: 2.5rem;
}

@media screen and (max-width: 950px) {
    .out-sop-show {
    flex-direction: column;
    margin-bottom: 3rem;
    justify-content: center;
    align-items: center;
    }

    .out-sop {
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    }

    .out-spo-case {
    max-width: 90%;
    margin: auto;
}
/* .sop-1  {
    width: 80%;
    height: 100px;
    margin-bottom: 12rem;
} */
}

@media screen and (max-width: 600px) {
    .sop-4 {
    font-size: 1.3rem;
}
}

/* outreach groups sponsors end */


/* wan to get connected */
.to-get {
    font-size: 2.5rem;
    color: #000;
    font-weight: 700;
    text-align: center;
    margin: 4rem 0;
}

@media screen and (max-width: 600px) {
    .to-get {
    font-size: 1.6rem;
}
}
/* wan to get connected end */


/* connect forms */

.form-wrapper {
    background: #F2F2F2;
    max-width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    border-radius: 6px;
}

.form-case {
    max-width: 100%;
    margin: auto;
}

.form-connect {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.fmcase {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.fm-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #000;
    width: 45%;
}

.i-name .star-form {
    color: var(--primary_color);
}

.i-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .4rem;
}

.i-form {
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    padding: .45rem .6rem;
}

.email-phone-wrapper {
    margin: 3rem 0;
    width: 100%;
}

.email-phone {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    width: 100%;
}

/* .em-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
} */


.iii {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.heya {
    padding: .2rem .5rem;
    border: #000 2px solid;
    border-right: none;
    border-radius: 5px 0 0 5px;
    height: 36px;
}

.ooo {
    border-radius: 0 5px 5px 0;
    width: 100%;
    margin-right: 3rem;
    height: 36px;
}
.ooo-2 {
    border-radius: 0 5px 5px 0;
    width: 100%;
    margin-right: 3rem;
    height: 36px;
}

.em-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 64%;
}

.em-4 .btn {
    color: var(--primary_color);
    font-weight: 600;
    padding: .5rem 1rem;
}

.em-4 .btn:hover {
    color: #fff;
}


/* .em-3 {
    margin-right: 2rem;
} */

/* btn */
/* btn */
.btn,
.btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--primary_color);
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  transition: all .3s ease-in;
}

.btn:hover,
.btn:hover{
  color: #fff;
  background-color: var(--primary_color);
}

.btn::before,
 .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

 .btn:hover::before,
 .btn:focus::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.em-4 .btn {
    margin: 0;
}


@media screen and (max-width: 800px) {
    .fmcase {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .fm-1 {
        width: 85%;
    }

    .fm-1:nth-child(1) {
        margin-bottom: 2rem;
    }

    .email-phone {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center ;
    }

    .em-1, .em-2 {
        width: 85%;
        margin-right: 0;
        margin: auto;
        margin-bottom: 2rem;
    }

    .em-3, .em-4 {
        margin-left: 10%;
    }

    .em-4 {
    width: 80%;
}
}

/* connect forms end */
.box-check input {
   margin-right: .5rem;
   cursor: pointer;
}

.ch-head {
   font-size: 16px;
   color: #000;
   text-transform: uppercase;
   font-weight: 600;
}

.checkbox-list-container {
  display: flex;
  flex-direction: column;
  grid-gap: .5em;
  gap: .5em;
}

.item-box {
   margin-left: .7rem;
}

#outreach_affiliated {
  margin-bottom: 5rem;
}

.wrapper {
  /* margin: 5rem auto; */
  padding: 5rem 0 0rem 0;
  /* border: 1px solid red; */
}

.wrapper h2 {
  margin: 1rem 0;
  color: var(--grey_color);
}

.wrapper p {
  margin: 1rem 0;
  color: var(--grey_color);
}

.wrapper ul {
  list-style: none;
}

.wrapper ul li::before {
  content: "\2022";
  color: var(--primary_color);
  font-weight: bold;
  font-size: 1.5rem;
  display: inline-block;
  margin-right: 0.2em;
}

.wrapper .outreach_detail_social {
  color: var(--primary_color);
  font-weight: 500;
  transition: ease-in-out 300ms;
}

.wrapper .outreach_detail_social:hover {
  opacity: 0.6;
}

.outreach_head {
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: var(--primary_color);
  margin: auto;
  width: 100%;
}

.outreach_head:first-child {
  margin-top: 5rem;
}

@media screen and (max-width: 600px) {
  .outreach_head {
    max-width: 90%;
    font-size: 2rem;
    margin-bottom: 0rem auto;
  }

  .outreach_head:first-child {
    margin-top: 6rem;
    text-align: center;
  }

  .wrapper h2 {
    font-size: 1.7rem;
  }
}

#prayer-wrapper {
  width: 100%;
  background: #fff;
}

.prayer-case {
  max-width: 80%;
  margin: auto;
  padding-top: 8rem;
}

.prayer-show {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.pray-left {
  width: 20%;
  border-bottom: #000 solid 1px;
}

.pl-txt {
  font-size: 3rem;
  font-weight: 800;
  color: #000;
  padding-bottom: 2rem;
  margin-right: 3rem;
}

.pray-right {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.pr {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5rem;
}

.pr-1 {
  background: #efefef;
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 3rem;
  border: #efefef solid 2px;
}

.pr1-tt1 {
  font-size: 2.2rem;
  color: #000;
  font-weight: 700;
}

.pr1-tt2 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 1rem 0;
}
.pr1-tt3 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.pr-2 {
  width: 100%;
}

.pf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.pf-1 {
  font-size: 1.2rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 1.8rem;
}

.pf .nl {
  width: 100%;
}
.pf .nl .nl2 {
  width: 100%;
}

.pr-3 .btn {
  font-size: 1.1rem;
}

.pr-2 form > div {
  margin-bottom: 1rem;
}

.pr-2 form input {
  width: 300px;
  height: 35px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #a7a7a7;
}

@media screen and (max-width: 950px) {
  .prayer-show {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .pray-left {
    width: 100%;
    border-bottom: #000 solid 1px;
  }

  .pl-txt {
    font-size: 2rem;
    padding-bottom: 2rem;
    margin-right: 0;
  }

  .pray-right {
    width: 100%;
  }

  .pr1-tt1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .pr1-tt1 {
    font-size: 1.3rem;
    font-weight: 700;
  }
  .pr-1 {
    padding: 2rem 1rem;
    margin-bottom: 3rem;
  }

  .pr-3 .btn {
    font-size: 1rem;
  }
}

.pf input {
  padding: 0 1rem;
}

/* showcase  */

.min-group-showcase-ya {
  /* background: url("../../assets/images/students.jpg") no-repeat center
    center/cover;  */
  background: url(/static/media/YAB2.54ad872a.jpg) no-repeat center center/cover;
  height: 100vh;
  width: 100%;
}

.min-group-case-ya {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}

/* @media screen and (max-width: 600px) {
  .min-group-showcase-ya {
    height: 70vh;
  }
} */

/* showcase end  */

/* about ya  */

.abt-ya-wrapper {
  width: 100%;
}

.abt-ya-case {
  margin-bottom: 5rem;
}

.abt-ya {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.abtya-left {
  width: 50%;
}

.yapic img {
  object-fit: cover;
  width: 100%;
  height: 500px;
}

.yapic {
  width: 50%;
  position: absolute;
  left: 0;
  top: -5rem;
}

.abtya-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5rem;
  margin-top: 3rem;
}

.ya-tt1 {
  font-size: 1.9rem;
  color: #000;
  font-weight: 800;
  margin-bottom: 1rem;
}
.ya-tt2 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

@media screen and (max-width: 950px) {
  .abt-ya-wrapper {
    width: 80%;
    margin: auto;
  }

  .abt-ya-case {
    margin-bottom: 5rem;
  }

  .abt-ya {
    flex-direction: column;
  }

  .abtya-left {
    width: 100%;
  }

  .yapic img {
    height: 400px;
  }

  .yapic {
    width: 100%;
  }

  .abtya-right {
    width: 100%;
    margin-left: 0;
    margin-top: 25rem;
  }

  .ya-tt2 {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .yapic img {
    height: 250px;
  }
  .abtya-right {
    margin-top: 15rem;
  }
}

/* about ya end */

/* young adults events  */

.ya-events-wrapper {
  max-width: 85%;
  margin: auto;
}

.ya-events {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ya-top {
  font-size: 2.5rem;
  color: #000;
  font-weight: 800;
  margin-bottom: 5rem;
}

/* young adults events end */

/* card  */

.ya-events-wrapper .card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.ya-events-wrapper .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  bottom: 3rem;
  cursor: pointer;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.card:hover .text {
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
  background-color: var(--grey_color);
}

.card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.card .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 60%;
  width: 100%;
  transition: var(--transition);
  -webkit-transform: translateY(10rem);
          transform: translateY(10rem);
}

.card .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.card .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}

.card:hover .text p:first-child {
  margin-bottom: 0;
}

.card .text a {
  justify-content: flex-start;
  padding: 0;
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {
  /* ======= EVENT SECTION =======   */
  /* .denver_events_wrapper {
      width: 85%;
      flex-direction: column;
  } */

  .card_wrapper {
    flex-direction: column;
  }

  .card_wrapper .text {
    min-height: 40%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-color: var(--grey_color);
  }

  .card .text p:first-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .ya-top {
    font-size: 2rem;
    margin-bottom: 5rem;
  }
}

/* card end */

/* podcast  */

.pod-wrapper {
  max-width: 85%;
  margin: auto;
}

.pod-case {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.podd {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pod-line {
  background: var(--primary_color);
  height: 2.5px;
  width: 40%;
}

.podcas {
  margin: 5rem 0;
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}

.pod-left {
  width: 45%;
}

.pod-pic {
  width: 100%;
  cursor: pointer;
}

.pod-pic img {
  object-fit: contain;
  height: 350px;
}

.pod-right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pod-tt1 {
  font-size: 25px;
  color: #000;
  font-weight: 800;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.pod-tt2 .linkAnima {
  color: var(--primary_color);
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0;
  justify-content: flex-start;
}
.pod-tt2 .linkAnima:hover {
  color: #000;
}

.pod-tt2 {
  margin-bottom: 1rem;
}

.pod-tt2:nth-last-child(1) {
  margin-bottom: 0;
}

@media screen and (max-width: 950px) {
  .podcas {
    margin: 5rem 0;
    flex-direction: column;
    justify-content: center;
  }

  .pod-right {
    width: 100%;
  }
  .pod-left {
    width: 100%;
    margin-bottom: 4rem;
  }
}

/* podcast end */

/* ya contact us  */

.ya-contact-wrapper {
  max-width: 80%;
  margin: auto;
}

.ya-contact-case {
  margin-bottom: 5rem;
}

.ya-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.yacon-1 {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  margin-bottom: 2rem;
}

.yacon-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.yac {
  padding: 2rem;
  background: #eee;
  width: 100%;
  margin-bottom: 1.5rem;
  border-radius: 5px;
}

.yac-btt .btn {
  font-size: 1.1rem;
}

.nmes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nmes .nl {
  width: 47%;
}

.yac .nl {
  width: 47%;
}

.yac .mess-ara {
  width: 100%;
}

.yac input {
  color: #5d5c5c;
  padding-left: 1rem;
  font-size: 1rem;
}

.mess-ara .nl2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.mess-ara .nl2 textarea {
  width: 100%;
  height: 6rem;
  color: #5d5c5c;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 5px;
  border: #a7a7a7 solid 1px;
}

@media screen and (max-width: 950px) {
  .nmes {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .nmes .nl {
    width: 100%;
  }

  .yac .nl {
    width: 100%;
  }
  .yacon-2 {
    justify-content: center;
    align-items: center;
  }
  .yacon-1 {
    font-size: 25px;
  }
}

/* ya contact us end */

#faq1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  /* margin: 2rem 0; */
}

#faq1 .faq-wrapper {
  width: 100%;
  margin: auto;
  padding-bottom: 3rem;
}

.faq-wrapper h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.faq-wrapper .accordion {
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
  transition: var(--transition);
}

.faq-wrapper .accordion p {
  font-size: 1.2rem;
}

.faq-wrapper .question {
  font-weight: 700;
  padding: 15px 0;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-wrapper .question span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  transition: -webkit-transform var(--transition);
  transition: transform var(--transition);
  transition: transform var(--transition), -webkit-transform var(--transition);
}

.faq-wrapper .question .activated {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faq-wrapper .question p {
  transition: color var(--transition);
}

.faq-wrapper .question:hover p, 
.faq-wrapper .question:focus p {
  color: var(--primary_color);
}

.faq-wrapper .accordion .dropdown {
  transition: all 300ms cubic-bezier(0,1,0,1);
  overflow: hidden;
}

.faq-wrapper .accordion .dropdown.drop {
  transition: all 300ms cubic-bezier(0,1,0,1);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 0;
}

.faq-wrapper .accordion .dropdown p {
  padding: 10px 20px 10px 0;
  font-size: 1rem;
  padding-bottom: 2rem;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
  #faq .faq-wrapper {
    padding: 0 2rem;
    width: 100%;
  }
}


#newsletter .newsletter_showcase {
  background: url(/static/media/stuu1.2ac581f3.jpg) no-repeat center center/cover;
  height: 37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.newsletter_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.newsletter_showcase h1 {
  color: #fff;
  font-size: var(--head_font);
  position: relative;
  z-index: 1;
}

.newsletter_form {
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 1rem;
  max-width: 85%;
  margin: auto;
}

#newsletter form {
  margin: 5rem auto;
  width: 100%;
}

#newsletter label {
  margin-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}

#newsletter .star::after {
  content: "";
  position: absolute;
  top: 4px;
  margin-left: 2px;
  background: rgb(207, 2, 2);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#newsletter input,
#newsletter select {
  display: block;
  width: 350px;
  height: 41px;
  outline: 0;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin-bottom: 1rem;
}

#newsletter input {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  color: #bdbdbd;
}

#newsletter input[type="submit"] {
  width: 100px;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--primary_color);
  border-radius: 0;
  color: #000;
  background-color: #fff;
  transition: 300ms ease;
  padding-left: 0;
  cursor: pointer;
}

#newsletter input[type="submit"]:hover {
  background-color: var(--primary_color);
  color: #fff;
}

#newsletter select {
  padding: 0.2rem;
  font-size: 1rem;
  color: #bdbdbd;
}

#newsletter input:focus,
#newsletter select:focus {
  box-shadow: 1px 1px 5px 3px rgba(164, 191, 202, 0.4);
}

#newsletter .name,
#newsletter .contact {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

/* ============ MEDIA QUERY ========== */

@media screen and (max-width: 1000px) {
  .newsletter_form {
    flex-direction: column;
    height: auto;
  }

  #newsletter .name,
  #newsletter .contact {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }

  #newsletter input,
  #newsletter select {
    width: 100%;
  }

  #newsletter input[type="submit"] {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .newsletter_showcase h1 {
    font-size: 2.5rem;
  }

  .newsletter_form {
    max-width: 100%;
  }

  #newsletter form {
    margin: 3rem auto;
  }
}

#serve_showcase {
  height: 35rem;
  background: url(/static/media/volunteer2.430ea971.jpg) no-repeat center
    center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#serve_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 0;
}

#serve_showcase h1 {
  font-size: 4rem;
  color: #fff;
  z-index: 1;
}

/* ======= SERVE_WHERE ======= */
#serve_where {
  min-height: 20rem;
  display: flex;
  margin: 3rem auto;
  margin-right: 0;
  max-width: 85%;
  grid-gap: 2rem;
  gap: 2rem;
}

#serve_where .right,
#serve_where .left {
  max-width: 100%;
}

#serve_where .left {
  width: 50%;
}

#serve_where .right {
  position: absolute;
  top: -7rem;
}

#serve_where .right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#serve_where .where_text {
  background-color: var(--primary_color);
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4rem 1rem 4rem 2rem;
}

#serve_where .where_text p {
  color: #fff;
  font-weight: 600;
  font-size: var(--head_font);
  line-height: 1.2;
}

#serve_where .shape {
  display: block;
  width: 100%;
  height: 15rem;
  background: rgb(146, 146, 146);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  position: absolute;
  bottom: -15px;
  left: 0;
  z-index: -1;
}

/* ======= SECTION: SERVE_CARDS ======= */
#serve_cards .cards_wrapper {
  max-width: 950px;
  margin: 5rem auto;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

#serve_cards .s_card {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #9b9b9b;
}

#serve_cards .cards_img img {
  height: 300px;
  border-radius: 7px;
}

#serve_cards .bottom .links {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

#serve_cards .cards_wrapper .bottom h3 {
  font-size: 2rem;
  text-transform: capitalize;
}

#serve_cards .cards_wrapper .bottom a {
  color: #000;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
}

#serve_cards .cards_wrapper .bottom a:hover,
#serve_cards .cards_wrapper .bottom a:focus {
  color: var(--primary_color);
}

#serve_cards .details {
  padding-top: 1rem;
  height: 0;
  overflow: hidden;
  transition: height ease 0.3s;
}

#serve_cards .details.reveal {
  height: auto;
}

#serve_cards .details p {
  color: #242424;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 1000px) {
  #serve_where {
    flex-direction: column-reverse;
    margin-right: auto;
    margin-top: 0;
  }

  #serve_where .shape {
    left: unset;
  }

  #serve_where .left {
    width: 100%;
  }

  #serve_where .right {
    top: -4rem;
  }

  /* ======= SECTION: SERVE_CARDS ======= */
  #serve_cards .cards_wrapper {
    max-width: 85%;
  }

  #serve_cards .cards_img img {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  #serve_where {
    max-width: 100%;
    padding: 1rem;
  }

  #serve_where .where_text {
    padding: 1rem;
    min-height: 130px;
  }

  #serve_where .where_text p {
    font-size: 2rem;
  }

  /* ======= SECTION: SERVE_CARDS ======= */
  #serve_cards .cards_wrapper {
    max-width: 100%;
    padding: 1rem;
    grid-template-columns: 1fr;
  }
  #serve_cards .cards_wrapper .bottom h3 {
    font-size: 1.7rem;
  }
}

#message_video {
  padding: 5rem 0;
  background: #000;
}

#message_video .video_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

#message_video .utube_video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#sermon_video {
  padding: 5rem 0;
  background: #000;
}

#sermon_video .video_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

#sermon_video .utube_video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* #id iframe {
  max-width: 100%;
} */

#privacy_policy {
  padding: 1rem;
}

#privacy_policy .privacy_wrapper {
  padding: 5rem 2rem;
}

#privacy_policy p {
  margin-bottom: 1rem;
}

#privacy_policy .p_head {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.2;
}

#privacy_policy .p_sub_head {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

@media screen and (max-width: 600px) {
  #privacy_policy {
    padding: 1rem 0;
  }

  #privacy_policy .privacy_wrapper {
    padding: 5rem 0;
    max-width: 90%;
  }

  #privacy_policy .p_head {
    font-size: 1.7rem;
  }
}

#terms_policy {
  padding: 1rem;
}

#terms_policy .terms_wrapper {
  padding: 5rem 2rem;
}

#terms_policy p {
  margin-bottom: 1rem;
}

#terms_policy .p_head {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.2;
}

#terms_policy .p_sub_head {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

@media screen and (max-width: 600px) {
  #terms_policy {
    padding: 1rem 0;
  }

  #terms_policy .privacy_wrapper {
    padding: 5rem 0;
    max-width: 90%;
  }

  #terms_policy .p_head {
    font-size: 1.7rem;
  }
}

.supportSection h1 {
    font-size: 60px !important;
    color: var(--primary_color);
    font-weight: 500;
    text-transform: unset;
}

.supportSection h1:nth-child(1) {
    margin: 0 0 0 auto;
}

.supportSection h1~h1 {
    margin: 0 auto 0 0;
}

.supportImg.img-fluid {
    width: 10rem;
    object-fit: cover;
}

.supportMargin {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
}

.supportMargin>h2 {
    color: var(--primary_color);
}

.paymentProcess>.container>.row>.col-12>h2 {
    color: var(--primary_color);
}

.paymentMethodList h2,
.paymentProcess h2 {
    font-weight: 500;
    text-transform: unset;
    font-size: 1.5rem;
}

ul.methodList,
.paymentProcess ul {
    padding: 0 30px;
    margin-bottom: 30px;
}

ul.methodList li::before,
.paymentProcess ul li::before {
    content: "\2022";
    color: var(--black-color);
    font-weight: bold;
    display: flex;
    width: 1em;
    margin-left: -1em;
    font-size: 30px;
}

ul.methodList li,
.paymentProcess ul li {
    /* list-style: disc; */
    color: var(--blue-dark);
    display: flex;
    align-items: center;
    margin: -20px 0;
}

.paymentProcess .card {
    border: 1px solid black;
    border-radius: 0;
}

.paymentProcess .card-header {
    padding: 3.5rem 3.5rem 0;
    border-bottom: none;
}

.paymentProcess .card-header p {
    font-size: 15px;
}

.paymentProcess .bank .card-header {
    color: var(--dim);
}

.paymentProcess ul {
    padding: 0 40px;
}

.paymentProcess ul li::before {
    width: unset;
}

.paymentProcess ul li {
    display: flex;
    align-items: center;
    margin: 0 0;
    font-size: 15px;
    color: var(--black-color);
}

.paymentProcess img.donateIcon {
    position: absolute;
    width: 120px;
    left: 0;
    right: 0;
    top: -12%;
    margin: 0 auto;
    height: auto !important;
}

.paymentProcess .card-group {
    grid-row-gap: 7rem;
    row-gap: 7rem;
}

.paymentProcess h2.cheque {
    color: var(--red-variant) !important;
}

.paymentProcess h2.emailMoney {
    color: var(--blue-dark-variant);
}

.paymentProcess .emailMoney span {
    color: var(--red);
}

.paymentProcess h2.other {
    color: var(--brown);
}

.stickerSection .row {
    width: 75%;
    display: flex;
    margin: 0 auto;
}

.btn {
    position: relative;
    color: #000;
    font-weight: 500;
    padding: 0.75rem 2.3rem;
    margin: 0.75rem;
    border: 1px solid var(--primary_color);
    transition: all var(--transition);
}

.bank-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
    width: 40%;
    height: 40%;
}

@media (max-width: 1200px) {
    .supportImg.img-fluid {
        width: 11%;
    }

    .supportSection h1 {
        font-size: 52px !important;
    }
}

@media (max-width: 991px) {
    .supportImg.img-fluid {
        width: 12%;
    }

    .supportSection h1 {
        font-size: 49px !important;
    }

}

@media (max-width: 768px) {
    .supportSection h1 {
        font-size: 35px !important;
    }

    .supportMargin {
        margin: 0;
    }

    ul.methodList li {
        margin: 0 0;
    }

    ul.methodList li::before {
        width: unset;
    }

    .stickerSection .row {
        width: 100%;
    }
}
#not_found {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1.5rem;
    text-align: center;
    line-height: 1.2;
}

#not_found h2 {
    font-size: 2rem;
}

#not_found p:nth-child(2) {
    font-size: 3rem;
    font-weight: 700;
}

#not_found a {
    color: var(--primary_color);
    transition: all var(--transition);
    font-weight: 600;
}

#not_found a:hover {
    text-decoration: underline;
    opacity: 0.9;
}

#not_found .main {
    max-width: 400px;
}

@media screen and (max-width:600px) {
    #not_found h2 {
        font-size: 1.7rem;
    }
    
    #not_found p:nth-child(2) {
        font-size: 2rem;
    }
}
/* nav 1 style */

.header-navbar {
  width: 100%;
  margin: auto;
  position: relative;
  position: fixed;
  z-index: 7;
}

.header-wrapper {
  background: transparent;
  position: relative;
  width: 100%;
  margin: auto;
  transition: all 0.2s ease-in;
}

.header-wrapper .header {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85%;
  margin: auto;
  z-index: 2;
}

@media screen and (max-width: 600px) {
  .header-wrapper .header {
    max-width: 95%;
    margin: auto;
  }
}

.header-wrapper .header .header-1 {
  height: 4rem;
  width: 4rem;
  padding: 0.5rem 0;
}

.header-wrapper .header .header-1 a .logo {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .header-wrapper .header .header-1 {
    height: 3rem;
    width: 3rem;
  }
}

.header-wrapper .header .header-2 .loc {
  /* font-size: 1.3rem; */
  font-size: 17px;
  font-weight: 900;
  color: #b5192a;
  -webkit-text-stroke: 0.4px #000;
  margin-right: 2rem;
}
.header-wrapper .header .header-2 a {
  text-decoration: none;
  cursor: pointer;
}

.header-wrapper .header .header-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper .header .header-2 .menu-icon {
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: relative;
}

.header-wrapper .header .header-2 .menu-icon .menu-1 {
  background: #b5192a;
  /* width: 2rem; */
  width: 1.5rem;
  height: 0.2rem;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all 0.4s;
  display: block;
  transition: all 0.4s ease-in;
}

.header-wrapper .header .header-2 .menu-icon .menu-3 {
  background: #b5192a;
  /* width: 2rem; */
  width: 1.5rem;
  height: 0.2rem;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all 0.4s;
  display: block;
  transition: all 0.4s ease-in;
}

/* removing last line */
/* .header-wrapper .header .header-2 .menu-icon .menu-3 {
    display: none;
} */

.header-wrapper .header .header-2 .menu-icon .menu-2 {
  background: #b5192a;
  /* width: 3rem; */
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.3rem 0;
  display: block;
  transition: all 0.4s ease-in;
}

.header-wrapper .header .header-2 .menu-icon a .menu-1 {
  transition: all 0.2s ease-in;
}

.header-wrapper .header .header-2 .menu-icon a .menu-3 {
  transition: all 0.2s ease-in;
}

.header-wrapper .header .header-2 .menu-icon a:hover .menu-1 {
  /* width: 3rem; */
  width: 2.5rem;
}

.header-wrapper .header .header-2 .menu-icon a:hover .menu-3 {
  /* width: 3rem; */
  width: 2.5rem;
}

/* nav 1 style end */

.menu-overlay {
  position: fixed;
  background: url(/static/media/nnav.579a86d7.jpg) no-repeat center center/cover;
  height: 100vh;
  width: 100%;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;
  z-index: 3;
  top: 0;
}

@media screen and (max-width: 1000px) {
  .menu-overlay {
    height: 100vh;
    width: 100%;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.hamBox {
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
  z-index: 5;
}

.header-wrapper .header .header-2 .menu-icon .menu-1.spin {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  background: #fff;
  /* width: 3rem; */
  width: 2.5rem;
  height: 0.2rem;
  z-index: 5;
}

.header-wrapper .header .header-2 .menu-icon .menu-2.spin {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  background: #fff;
  /* width: 3rem; */
  width: 2.5rem;
  margin: 0 0;
  height: 0.2rem;
  z-index: 5;
}

.header-wrapper .header .header-2 .menu-icon .menu-3.spin {
  /* transform: rotate(525deg);
    background: rgb(255, 255, 255); */
  display: none;
}

/* overlay nav links  */

/* ul removed */

.nav-links {
  position: absolute;
  top: 25%;
  margin: 0 0;
  padding: 0 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .nav-links {
    position: absolute;
    top: 20%;
    flex-direction: column;
    left: 40%;
  }
}
@media screen and (max-width: 600px) {
  .nav-links {
    position: absolute;
    top: 15%;
    flex-direction: column;
    left: 25%;
  }
}

.nav-links .nav-item {
  list-style: none;
  position: relative;
}

.nav-links .nav-item a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 0;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

@media screen and (max-width: 1000px) {
  .nav-links .nav-item {
    margin-bottom: 2rem;
  }
}

.nav-item .neww .hover_overlay {
  width: 100%;
}
/* ul end */

/* adding shape */

.nav-item a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  display: block;
}
.nav-item .hel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  display: block;
}

.nav-links .nav-item .hel {
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  position: relative;
  top: 0;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
}

.nav-item a .shape {
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background: #b5192a;
  height: 2.5rem;
  width: 2.5rem;
}
.nav-item .hel .shape {
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background: #b5192a;
  height: 2.5rem;
  width: 2.5rem;
}

.nav-item .men:hover {
  color: #b5192a;
}

/* adding shape */

/* overlay nav links end */

/* DROPDOWN */

.nav-item {
  position: relative;
  display: inline-block;
  width: 185px;
}

.neww {
  width: 115px;
  margin: 0 1rem;
}

.neww a {
  transition: ease var(--transition);
}

.neww a:hover {
  color: #b5192a;
}

.dropdown-menu a {
  position: absolute;
  left: 0;
  color: rgb(55, 30, 220);
  font-size: 10px;
  top: calc(100% + 0.5rem);
  padding: 0.8rem;
  border-radius: 0.25rem;
  padding-left: 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: all 0.3s ease-in-out;
  /* width: 185px; */
  display: none;
}

@media screen and (max-width: 1000px) {
  .nav-item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .dropdown-menu a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.dropdown-menu {
  padding-top: 1rem;
}

.nav-item:hover .dropdown-menu a {
  display: block;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: all 0.3s ease-in-out;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-item:hover {
  display: block;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: all 0.3s ease-in-out;
}

.men {
  font-size: 17px;
  margin-right: 0.5rem;
}

.nav-links .nav-item a {
  font-size: 17px;
}

.zenn {
  margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
  .men {
    margin: 2rem 0;
  }
}

/* DROPDOWN */

/* 
@media screen and (max-width: 600px) {
    .nav-links .nav-item a {
        font-size: 20px;
    }
} */

/* LOCATION BUTTON TOGGLE */
/* .toggle--button {
    font-size: 1.3rem;
    font-weight: 900;
    color: #B5192A;
    -webkit-text-stroke: .4px #000;
    margin-right: 2rem;
    border: none;
    cursor: pointer;
}

.toggle--close {
    font-size: 1.3rem;
    font-weight: 900;
    color: #B5192A;
    -webkit-text-stroke: .4px #000;
    margin-right: 2rem;
    border: none;
    background: transparent;
    cursor: pointer;
} */

button {
  font-size: 18px;
  font-weight: 900;
  color: #b5192a;
  -webkit-text-stroke: 0.4px #000;
  margin-right: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
}

.btn-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconn {
  margin-right: 0.5rem;
  color: #b5192a;
}

@media screen and (max-width: 800px) {
  .btn-name .loc-n1 {
    display: none !important;
  }
}

/* transparent bg */

.header-bg {
  background: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease-in;
}

/* transparent bg end */

/* @media screen and (max-width: 600px) {
    .nav-links .nav-item a  {
        font-size: 19px;
    }

    .men {
        font-size: 19px;
    }
} */

#footer {
  background-color: var(--black_color);
  min-height: 350px;
  padding: 4rem 1rem;
}

.footer_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.foot_content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1.2fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* align-items: flex-start;
  justify-content: space-between; */
  margin-bottom: 1rem;
}

.footer_wrapper .links {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  color: #f4f4f4;
}

.footer_wrapper .links:hover {
  color: var(--primary_color);
}

.foot_content h3 {
  display: flex;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.footer__small {
  margin-left: 3px;
  font-size: 10px;
}
.content_Quick_links2 p {
  color: #fff;
  text-align: start;
}

.footer_divider {
  margin: auto;
  width: 100%;
  height: 2px;
  background-color: #f4f4f4;
}

#footer .content_bottom {
  margin-top: 2rem;
}

#footer .menu {
  flex-basis: 25%;
}

#footer .content_bottom p {
  color: #fff;
  font-size: 12px;
  padding: 0.3rem 0;
}

#footer .content_bottom p a {
  padding-left: 0.3rem;
}

#footer.content_bottom p a:hover,
#footer .content_bottom p a:focus {
  opacity: 0.5;
}

#footer .content_bottom a {
  color: var(--primary_color);
  font-size: 12px;
  font-weight: 500;
  transition: color 300ms ease;
}

#footer .content_bottom a:hover,
#footer .content_bottom a:focus {
  color: #fff;
}

#footer .social {
  display: flex;
}

#footer .social a {
  font-size: 2rem;
  padding: 1rem 1rem 1rem 0;
  transition: var(--transition);
}

#footer .social a:hover,
#footer .social a:focus {
  color: var(--primary_color);
}

/* =============== Media Query =============== */
@media screen and (max-width: 1000px) {
  .foot_content {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .foot_content {
    grid-template-columns: 1fr;
  }

  #footer .social a {
    font-size: 1.7rem;
    padding: 0.75rem;
  }
}

