.out-showcase {
    background: url('../../assets/images/gpo3.jpeg') no-repeat center center/cover;
    height: 100vh;
}

.out-case {
    background: rgba(0, 0, 0, .6);
    height: 100%;
}

.out-text {
    color: #fff;
    text-align: center;
    font-weight: 700;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

/* @media screen and (max-width: 600px) {
    .out-showcase {
    height: 70vh;
}

} */


/* local outreach  */

.local-wrapper {
    background: #fff;
    margin: 5rem 0;
}

.local-case {
    max-width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.local-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.ll-1 {
    color: var(--primary_color);
    border-bottom: var(--primary_color) solid 2px;
    padding: 0 .5rem;
    margin-right: 4rem;
}

.ll-2 {
    color: #000;
    border-bottom: #000 solid 2px;
    padding: 0 .5rem;
}

.local-2 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary_color);
    margin: 2rem 0;
    text-align: center;
    text-transform: uppercase;
}

.local-3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    text-align: left;
    margin-bottom: 1.5rem;
}

.local-4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 3.5rem;
}

.local-5 {
    background-color: var(--primary_color);
    height: 2px;
    width: 100%;
}


@media screen and (max-width: 600px) {
    
.local-2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary_color);
    margin: 2rem 0;
    text-align: center;
}

.local-3 {
    font-size: 16px;
}

.local-4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 3.5rem;
}
}

/* local outreach end */



/* outreach groups sponsors */


.out-spo-wrapper {
    background: #fff;
}

.out-spo-case {
    width: 85%;
    margin: auto;
}

.out-sop-show {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    align-items: center;
    gap: 2em;
    width: 100%;
    margin-bottom: 2rem;
}

.out-sop {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: center;
    height: 400px;
}

/* .sop-1  {
    width: 250px;
    height: 100px;
} */

.sop-1 img {
    object-fit: contain;
    width: 80%;
    margin: auto;
    height: 200px;
}

.sop-2 {
    color: var(--primary_color);
    font-size: 16px;
    cursor: pointer;
    margin: 1rem 0;
}
.sop-2:hover {
    color: #000;
}

.sop-3 {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.sop-4 {
    font-size: 1.5rem;
    color: var(--primary_color);
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    margin: .6rem 0;
}

.out-sop .linkAnima {
    justify-content: flex-start;
    padding: 0;
    color: #000;
}
.out-sop .linkAnima:hover {
    color: var(--primary_color);
}

.sop-6 {
    background: #000;
    width: 100%;
    height: 2px;
    margin-top: 2.5rem;
}

@media screen and (max-width: 950px) {
    .out-sop-show {
    flex-direction: column;
    margin-bottom: 3rem;
    justify-content: center;
    align-items: center;
    }

    .out-sop {
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    height: fit-content;
    }

    .out-spo-case {
    max-width: 90%;
    margin: auto;
}
/* .sop-1  {
    width: 80%;
    height: 100px;
    margin-bottom: 12rem;
} */
}

@media screen and (max-width: 600px) {
    .sop-4 {
    font-size: 1.3rem;
}
}

/* outreach groups sponsors end */


/* wan to get connected */
.to-get {
    font-size: 2.5rem;
    color: #000;
    font-weight: 700;
    text-align: center;
    margin: 4rem 0;
}

@media screen and (max-width: 600px) {
    .to-get {
    font-size: 1.6rem;
}
}
/* wan to get connected end */


/* connect forms */

.form-wrapper {
    background: #F2F2F2;
    max-width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    border-radius: 6px;
}

.form-case {
    max-width: 100%;
    margin: auto;
}

.form-connect {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.fmcase {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.fm-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #000;
    width: 45%;
}

.i-name .star-form {
    color: var(--primary_color);
}

.i-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .4rem;
}

.i-form {
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    padding: .45rem .6rem;
}

.email-phone-wrapper {
    margin: 3rem 0;
    width: 100%;
}

.email-phone {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    width: 100%;
}

/* .em-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
} */


.iii {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.heya {
    padding: .2rem .5rem;
    border: #000 2px solid;
    border-right: none;
    border-radius: 5px 0 0 5px;
    height: 36px;
}

.ooo {
    border-radius: 0 5px 5px 0;
    width: 100%;
    margin-right: 3rem;
    height: 36px;
}
.ooo-2 {
    border-radius: 0 5px 5px 0;
    width: 100%;
    margin-right: 3rem;
    height: 36px;
}

.em-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 64%;
}

.em-4 .btn {
    color: var(--primary_color);
    font-weight: 600;
    padding: .5rem 1rem;
}

.em-4 .btn:hover {
    color: #fff;
}


/* .em-3 {
    margin-right: 2rem;
} */

/* btn */
/* btn */
.btn,
.btn {
  position: relative;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--primary_color);
  transition: transform var(--transition);
  transition: all .3s ease-in;
}

.btn:hover,
.btn:hover{
  color: #fff;
  background-color: var(--primary_color);
}

.btn::before,
 .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--primary_color);
  transform-origin: top;
  transition: transform var(--transition);
  transform: scaleX(0);
}

 .btn:hover::before,
 .btn:focus::before {
  transform: scaleX(1);
}

.em-4 .btn {
    margin: 0;
}


@media screen and (max-width: 800px) {
    .fmcase {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .fm-1 {
        width: 85%;
    }

    .fm-1:nth-child(1) {
        margin-bottom: 2rem;
    }

    .email-phone {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center ;
    }

    .em-1, .em-2 {
        width: 85%;
        margin-right: 0;
        margin: auto;
        margin-bottom: 2rem;
    }

    .em-3, .em-4 {
        margin-left: 10%;
    }

    .em-4 {
    width: 80%;
}
}

/* connect forms end */