#footer {
  background-color: var(--black_color);
  min-height: 350px;
  padding: 4rem 1rem;
}

.footer_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.foot_content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1.2fr;
  gap: 1.5rem;
  /* align-items: flex-start;
  justify-content: space-between; */
  margin-bottom: 1rem;
}

.footer_wrapper .links {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  color: #f4f4f4;
}

.footer_wrapper .links:hover {
  color: var(--primary_color);
}

.foot_content h3 {
  display: flex;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.footer__small {
  margin-left: 3px;
  font-size: 10px;
}
.content_Quick_links2 p {
  color: #fff;
  text-align: start;
}

.footer_divider {
  margin: auto;
  width: 100%;
  height: 2px;
  background-color: #f4f4f4;
}

#footer .content_bottom {
  margin-top: 2rem;
}

#footer .menu {
  flex-basis: 25%;
}

#footer .content_bottom p {
  color: #fff;
  font-size: 12px;
  padding: 0.3rem 0;
}

#footer .content_bottom p a {
  padding-left: 0.3rem;
}

#footer.content_bottom p a:hover,
#footer .content_bottom p a:focus {
  opacity: 0.5;
}

#footer .content_bottom a {
  color: var(--primary_color);
  font-size: 12px;
  font-weight: 500;
  transition: color 300ms ease;
}

#footer .content_bottom a:hover,
#footer .content_bottom a:focus {
  color: #fff;
}

#footer .social {
  display: flex;
}

#footer .social a {
  font-size: 2rem;
  padding: 1rem 1rem 1rem 0;
  transition: var(--transition);
}

#footer .social a:hover,
#footer .social a:focus {
  color: var(--primary_color);
}

/* =============== Media Query =============== */
@media screen and (max-width: 1000px) {
  .foot_content {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .foot_content {
    grid-template-columns: 1fr;
  }

  #footer .social a {
    font-size: 1.7rem;
    padding: 0.75rem;
  }
}
